@import "../../../../theme/variable-switch.scss";

.box-search-select-options-rooms {
	.box-select-pax {
		max-height: 360px;
		padding: 0px 5px !important;
		overflow-y: auto;
		margin-bottom: 10px;
		@media (min-width: $md) {
			width: 220px;
		}
	}

	.box-select-people-search {
		padding: 0 !important;

		.box-select-people-search-container {
			display: flex;
			justify-content: space-between;
			align-items: center;
		}

		.subtext {
			font-size: 14px;
		}

		.container-select-years {
			display: flex;
			justify-content: flex-start;
			flex-wrap: wrap;
			width: auto;
			margin: 0;
			padding: 0;
			gap: 3px;
			margin-top: 10px;
			.box-years {
				padding-bottom: 3px;
				width: calc(100% / 3) - 1;
				margin: 0;
			}
			p{
				padding: 0;
				margin: 0;
			}

			&:empty{
				display: none !important;
			}
		}
	}

	.hover-text-bg:hover {
		background-color: $primaryTransparent !important;
		color: $primary !important;

	}

	.hover-text-bg:active {
		border: none !important;
		background-color: transparent !important;
		color: $primary !important;
	}
}