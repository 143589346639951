@import "../../../theme/variable-switch.scss";

.box-tittle-column-state {
    height: 100px !important;
    overflow: hidden;
    transition-duration: 3s, 2s;
    transition-delay: 2s, 3s;
}

.text-subtitle{
    font-size: $text-size-aux;
    margin-bottom: 24px;
    margin-top: 24px;
}
