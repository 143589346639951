@import "../theme/variable-switch.scss";

.search-nexus {
  .search-box button.btn-book-submit.btn.btn-primary {
    background: linear-gradient(230deg,
        #fd921e 0%,
        #ff621d 48.44%,
        #e73a4e 97.92%);
    color: #ffffff !important;
    border: none;

    svg {
      margin-right: 0px !important;
      color: #ffffff !important;
    }
  }

  button.calendar-button-render-input.w-100.btn.btn-Light svg {
    color: $textWhite !important;
    font-weight: 700;
  }

  .mzp-autocomplete.has-icon .input-group-text svg {
    color: $textWhite;
    font-weight: 700;
  }

  .div-border-searchbox {
    display: flex;
    justify-content: center;
    width: 100%;

    .border-searchbox {
      height: 20px;
      background-color: #ff621d;
      border-radius: 9px;
      width: 83%;
      margin-top: -9px;
      display: flex;
      justify-content: left;
      left: 50;
    }
  }

  .container-booking-fluid .container-booking-fluid-content,
  .container-booking-fluid-hotel .container-booking-fluid-content {
    text-align: center;
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
  }

  .container-booking-fluid,
  .container-booking-fluid-hotel {
    border-bottom-right-radius: 70px;
    border-bottom-left-radius: 70px;
  }

  .container-booking-fluid .container-booking-fluid-content h1,
  .container-booking-fluid-hotel .container-booking-fluid-content h1 {
    padding-top: 0 !important;
  }

  .calendar-button-render-input span {
    color: $textWhite !important;
    font-weight: 100;
    margin-top: 0.0rem !important;
  }

  .button-overlay .content {
    color: $textWhite !important;
    font-weight: 700;
  }


  @media (min-width: 769px) {

    .container-booking-fluid .container-booking-fluid-content .search-box,
    .container-booking-fluid-hotel .container-booking-fluid-content .search-box {
      display: flex;
      justify-content: center;
      align-content: center;
      flex-direction: column;
      flex-wrap: wrap;
    }

    .search-box .label-error {
      width: 300px;
    }


    .container-booking-fluid .container-booking-fluid-content .search-box .button-overlay,
    .container-booking-fluid .container-booking-fluid-content .search-box .calendar-button-render-input,
    .container-booking-fluid-hotel .container-booking-fluid-content .search-box .button-overlay,
    .container-booking-fluid-hotel .container-booking-fluid-content .search-box .calendar-button-render-input {
      background: transparent !important;
      border: none !important;
      border-right: 1px solid white !important;
      border-radius: 0 !important;
    }

    .search-box {
      .mzp-autocomplete.has-icon input {
        background: transparent;
        border: none;
        border-right: 1px solid white;
        border-radius: 0;
        font-weight: 700;
      }

      .search-box-row {
        border-radius: 25px;
        border: 1px solid rgba(255, 255, 255, 0.25);
        background: rgba(255, 255, 255, 0.25);
        box-shadow: 1px 1px 47px 0px rgba(0, 0, 0, 0.43);
        backdrop-filter: blur(6.5px);

        padding: 10px;
        -webkit-backdrop-filter: blur(6.5px) !important;
        backdrop-filter: blur(6.5px) !important;
        width: 85%;
      }

      .search-box-col {
        display: flex;
      }
    }

    button.btn-book-submit.btn.btn-primary {
      border-radius: $border-radius-btn !important;

      svg {
        color: $textWhite;
        font-weight: 700;
      }
    }

    .container-booking-fluid .container-booking-fluid-content .title,
    .container-booking-fluid-hotel .container-booking-fluid-content .title {
      text-align: center;
      color: $textWhite;
      text-shadow: none !important;
      font-style: normal;
      font-weight: 700;
      line-height: 125.1%;
    }

    .search-box .search-box-row {
      align-items: center;
    }

    .container-booking-fluid .container-booking-fluid-content .button-input-custom,
    .container-booking-fluid-hotel .container-booking-fluid-content .button-input-custom {
      border-radius: 25px;
      border-color: rgba(255, 255, 255, 0.45) !important;
      background: rgba(255, 255, 255, 0.45) !important;
      box-shadow: 1px 1px 47px 0px rgba(0, 0, 0, 0.09) !important;
      backdrop-filter: blur(6.5px) !important;
      color: $textWhite !important;
    }

    .banner-video-right-section {
      padding-top: 4rem;
    }

    .search-box-col.btn-div.col {
      max-width: 40px;
      height: 40px;
    }

    .search-box button.btn-book-submit.btn.btn-primary {
      background: linear-gradient(230deg,
          #fd921e 0%,
          #ff621d 48.44%,
          #e73a4e 97.92%);
      color: #ffffff !important;
      border: none;
      border-radius: 50%;
      padding: 0;
      display: flex;
      font-weight: 700;
      justify-content: center;
      height: 40px;

      svg {
        margin-right: -2px !important;
      }
    }
  }

  @media (max-width: 769px) {

    .search-nexus .container-booking-fluid .container-booking-fluid-content .search-box,
    .search-nexus .container-booking-fluid-hotel .container-booking-fluid-content .search-box {
      margin-top: 50px;
    }

    .container-booking-fluid .container-booking-fluid-content h1,
    .search-nexus .container-booking-fluid-hotel .container-booking-fluid-content h1 {
      font-weight: 700;
      padding-bottom: 14px;
      padding-top: 50px !important;
    }

    .search-nexus .container-booking-fluid .container-booking-fluid-content h1,
    .search-nexus .container-booking-fluid-hotel .container-booking-fluid-content h1 {
      font-weight: 700;
    }

    .container-booking-fluid .container-booking-fluid-content,
    .container-booking-fluid-hotel .container-booking-fluid-content {
      height: 330px !important;
      padding-top: 0px;
      padding-bottom: 10px;
    }

    .search-box button.btn-book-submit.btn.btn-primary {
      border-radius: 0;
    }

    .search-box .search-box-row {
      margin-top: 30px;
    }

    .container-booking-fluid,
    .container-booking-fluid-hotel {
      margin-top: -5rem;
    }

    .mzp-autocomplete.has-icon input {
      background: rgba(255, 255, 255, 0.45) !important;

      border: 1px solid rgba(255, 255, 255, 0.45);
      box-shadow: 1px 1px 47px 0px rgba(0, 0, 0, 0.09) !important;

      -webkit-backdrop-filter: blur(6.5px) !important;
      backdrop-filter: blur(6.5px) !important;
      color: $textWhite !important;
      border-radius: 8px;

      svg {
        color: $textWhite;
      }
    }




    .container-booking-fluid .container-booking-fluid-content .search-box .button-overlay,
    .container-booking-fluid .container-booking-fluid-content .search-box .calendar-button-render-input,
    .container-booking-fluid-hotel .container-booking-fluid-content .search-box .button-overlay,
    .container-booking-fluid-hotel .container-booking-fluid-content .search-box .calendar-button-render-input {
      background: rgba(255, 255, 255, 0.45) !important;

      border: 1px solid rgba(255, 255, 255, 0.45);
      box-shadow: 1px 1px 47px 0px rgba(0, 0, 0, 0.09) !important;
      -webkit-backdrop-filter: blur(6.5px) !important;
      backdrop-filter: blur(6.5px) !important;
      color: $textWhite !important;
      border-radius: 8px;

      svg {
        color: $textWhite;
      }
    }
  }

  @media (max-width: $sm) {
    .search-box .search-box-row {
      background: none !important;
      box-shadow: none !important;
      border: none !important;
    }

    .search-nexus .search-box button.btn-book-submit.btn.btn-primary {
      border-radius: $border-radius-btn-mobile-search !important;
    }

    button.calendar-button-render-input.w-100.btn.btn-Light svg {
      color: $primary !important;
      font-weight: 700;
    }

    button#combo-autocomplete {
      background: rgba(255, 255, 255, 0.45) !important;
      box-shadow: 1px 1px 47px 0px rgba(0, 0, 0, 0.09) !important;
      backdrop-filter: blur(6.5px) !important;
      color: $textWhite !important;
      padding: 0 20px 0 20px !important;
      border-radius: 26px !important;

      svg {
        color: #ffffff !important;
      }
    }

    button#input-paxs {
      background: rgba(255, 255, 255, 0.45) !important;
      box-shadow: 1px 1px 47px 0px rgba(0, 0, 0, 0.09) !important;
      backdrop-filter: blur(6.5px) !important;
      color: $textWhite !important;

      svg {
        color: #ffffff !important;
      }
    }

    .container-booking-fluid .container-booking-fluid-content .button-input-custom,
    .container-booking-fluid-hotel .container-booking-fluid-content .button-input-custom {
      background: rgba(255, 255, 255, 0.45) !important;
      box-shadow: 1px 1px 47px 0px rgba(0, 0, 0, 0.09) !important;
      backdrop-filter: blur(6.5px) !important;
      color: $textWhite !important;
    }

    button.calendar-button-render-input.w-100.btn.btn-Light {
      background: rgba(255, 255, 255, 0.45) !important;
      box-shadow: 1px 1px 47px 0px rgba(0, 0, 0, 0.09) !important;
      backdrop-filter: blur(6.5px) !important;
      color: $textWhite !important;
    }

    .search-box {
      background: none;
      border: none;
      box-shadow: none;
    }

    .container-booking-fluid .container-booking-fluid-content,
    .container-booking-fluid-hotel .container-booking-fluid-content {
      min-height: 330px;
    }
  }

  @media (max-width: $lg) {

    .container-booking-fluid .container-booking-fluid-content .search-box,
    .container-booking-fluid-hotel .container-booking-fluid-content .search-box {
      padding: 0;
    }

    .search-box .search-box-row {
      width: 100%;
    }

    .div-border-searchbox {
      .border-searchbox {
        width: 70%;
        height: 12px;
        background-color: #ff621d;
        border-radius: 5px;
        margin-top: -6px;
        display: flex;
        justify-content: left;
        left: 50;
      }
    }
  }

  @media ((min-width: $sm) and (max-width: $md)) {

    .search-nexus .container-booking-fluid .container-booking-fluid-content,
    .search-nexus .container-booking-fluid-hotel .container-booking-fluid-content {
      height: 500px !important;
    }

    .search-nexus .container-booking-fluid .container-booking-fluid-content .search-box,
    .search-nexus .container-booking-fluid-hotel .container-booking-fluid-content .search-box {
      margin-top: 8rem;
    }

    .search-nexus .search-box button.btn-book-submit.btn.btn-primary {
      border-radius: $border-radius-btn !important;
    }
  }

  .search-box .mzp-autocomplete.has-icon input {
    color: $textWhite !important;
  }

  ::-webkit-input-placeholder {
    color: rgb(248, 248, 248);
  }

  ::-moz-placeholder {
    color: rgb(248, 248, 248);
  }

  :-ms-input-placeholder {
    color: rgb(248, 248, 248);
  }

  input:-moz-placeholder {
    color: rgb(248, 248, 248);
  }

  button#input-paxs {
    border-right: none !important;
  }

  .search-box .calendar-button-render-input .content {
    color: $textWhite;
  }
}

.container-title {
  font-weight: 700 !important;
  text-align: center !important;
}

.search-box .alert-text {
  height: 0 !important;
  padding: 0 !important;
}

@media (max-width: $md) {

  .search-nexus .container-booking-fluid .container-booking-fluid-content .search-box,
  .search-nexus .container-booking-fluid-hotel .container-booking-fluid-content .search-box {
    margin-top: 7rem;
  }

  .search-nexus .container-booking-fluid,
  .search-nexus .container-booking-fluid-hotel {
    margin-top: -7rem !important;
  }


  .header #dropdown-currencies {
    display: none !important;
  }

  .header .nav-item.dropdown .dropdown-toggle {
    display: none !important;
  }

  .search-nexus .search-box button.btn-book-submit.btn.btn-primary {
    border-radius: $border-radius-btn-mobile-search !important;

    svg {
      margin-right: -2px !important;
    }
  }

  .search-box .calendar-button-render-input .label {
    color: $textWhite;
    font-weight: 100;
  }

  .search-box .calendar-button-render-input .content {
    color: $text-color;
  }
}

@media (min-width: $md) {

  .search-nexus .container-booking-fluid .container-booking-fluid-content,
  .search-nexus .container-booking-fluid-hotel .container-booking-fluid-content {
    height: 450px;
  }

  .search-nexus .search-box button.btn-book-submit.btn.btn-primary {
    border-radius: 50% !important;
  }

  .hoteList {
    button.btn-book-submit.btn.btn-primary {
      border-radius: $border-radius-btn !important;
    }
  }
}

@media (max-width: $sm) {

  .search-nexus .container-booking-fluid .container-booking-fluid-content .button-input-custom,
  .search-nexus .container-booking-fluid-hotel .container-booking-fluid-content .button-input-custom {
    background: rgba(0, 0, 0, 0.09);
    box-shadow: 1px 1px 47px 0px rgba(0, 0, 0, 0.09);
    border-color: transparent;
    padding: 0 20px 0 20px !important;
    border-radius: 26px !important;
  }
}

@media ((min-width: $sm) and (max-width: $md)) {

  .search-nexus .container-booking-fluid,
  .search-nexus .container-booking-fluid-hotel {
    height: 450px;
    padding-top: 10px !important;
  }

  button.btn-book-submit.btn.btn-primary {
    border-radius: 8px !important;
  }

}

@media ((min-width: $md) and (max-width: $lg)) {
  .search-box {
    .search-box-row {
      width: 110% !important;
    }
  }

  .search-nexus .search-box-col.btn-div.col {
    max-width: 40px !important;
  }
}

.search-nexus .container-booking-fluid .container-booking-fluid-content,
.search-nexus .container-booking-fluid-hotel .container-booking-fluid-content {
  padding: 0 20px 0 20px !important;
}

.container-title {
  .title {
    font-weight: 700;

    span {
      color: $primary;
    }
  }
}

.top-destination-container .img {
  border-radius: $border-radius;
  border-bottom-left-radius: $card-border-bottom-left-radius;
  border-bottom-right-radius: $card-border-bottom-left-radius;
}

.search-box .mzp-autocomplete .cleaner-btn {
  height: 20px !important;
  width: 20px !important;
  border-radius: 50% !important;
  background: $btn-background !important;
  color: $btn-color !important;
  font-size: $text-size-small;
}

.top-destination-container .img:hover {
  transition: none;
  transform: none;
}

button.btn-book-submit.btn.btn-primary {
  svg {
    color: $textWhite;
    font-weight: 700;
  }
}


.hoteList {
  .container {
    padding: 0;
    margin: 0;
  }

  .container-fluid {
    padding: 0;
    margin: 0;
  }

  .withoutBackground {
    padding: 6px 0 0 0;
  }

  .search-box-row.flex-md-nowrap.row {
    width: 100% !important;
  }
}

.hotelDetail {
  .container {
    padding: 0;
    margin: 0;
  }

  .container-fluid {
    padding: 0;
    margin: 0;
  }

  .withoutBackground {
    padding: 6px 0 0 0;
  }

  .search-box-row.flex-md-nowrap.row {
    width: 100% !important;
  }
}

.search-box-service-hotel {
  .container-fluid {
    padding: 0;
    margin: 0;
  }
}

@media ((min-width: $sm)) {
  .priceview-mobile {
    display: none !important;
  }
}

@media ((min-width: 768px) and (max-width: 769px)) {
  .search-box .search-box-col {
    display: flex;
  }

  .search-nexus .search-box-col.btn-div.col {
    display: flex;
    max-width: 100% !important;
  }

  .search-nexus .search-box button.btn-book-submit.btn.btn-primary {
    border-radius: $border-radius-btn !important;
  }

  .search-nexus .search-box .search-box-row {
    width: 80% !important;
  }

  .search-nexus .container-booking-fluid .container-booking-fluid-content .search-box,
  .search-nexus .container-booking-fluid-hotel .container-booking-fluid-content .search-box {
    display: flex;
    align-content: center;
    justify-content: center;
  }

  .search-box .label-error {
    display: none;
  }
}

.options-select {
  .form-select {
    border-radius: 8px;
    width: auto;
  }
}

.search-nexus button#input-paxs svg {
  color: $textWhite !important;
}


.search-nexus .mzp-autocomplete.has-icon .input-group-text svg {
  color: $textWhite !important;
}