@import "../../../../theme/variable-switch.scss";

.container-baggage{
    .baggage-wrap{
        min-height: 422px;
        height: 100%;
        margin: 0 auto;
        padding: 15px 10px 10px; 
        box-shadow: 0 0.13rem 0.5rem 0 rgb(0 0 0 / 6%), 0 0.06rem 0.13rem 0 rgb(0 0 0 / 8%);
        .titleBaggage{
            min-height: 58px;
            text-align: center;
            font-size: $text-size;
            font-weight: 400;
            padding-left: 25px;
            padding-right: 25px;
            strong{
                display: block;
            }
        }
        .bag-img{
            padding: 20px 0 30px;
            img{
                width: 100%;
                margin: 0 auto;
                display: block;
                max-height: 245px;
                max-width: 180px;
            }
            img.complete-docu{
                padding: 30px;
            }
        }
        .text-info-baggage{
            p{
                font-size: $text-size-little;
                line-height: 17px;
                margin-top: 0; 
            }
        }
    }
    .carousel {
        padding-bottom: 40px;
    }
    .carousel-indicators [data-bs-target]{
        background-color: $backgroundBoxDark;
        border-radius: 50%;
        width: 15px;
        height: 15px;
    }
}
@media (max-width: $md) {
    .container-baggage .baggage-wrap{
        box-shadow: none;
    }
    .flightItemDetails .detail-baggage{
        flex-wrap: wrap;
        margin-top: 10px;
    }
    .flightItemDetails .detail-baggage .btn-bagagge{
        margin-bottom: 10px;
        font-weight: 600;
    }
}