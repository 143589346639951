
@import "../../../../theme/variable-switch.scss";
.booking-engine-tour {

    .search-room-button-render-input {
        min-width: 90px;
    }
    .calendar-button-render-input{
        min-width: 90px;
    }
    .mzp-autocomplete input{
        min-width: 130px;
    }

    @media (min-width:$md) {
        .booking-engine-col.btn-div.col{
            max-width: 220px;

        }
    }
}