@import "../../../../theme/variable-switch.scss";

@media (min-width: $md) {
  .container-card-activity {
    flex-direction: row !important;
  }
}

.container-card-activity {
  height: auto !important;
  border-radius: $border-radius;
  
  .card-body {
    display: flex;
    justify-content: space-between;
    .container-text-icon {
      justify-content: start;
    }
    .container-left {
      display: flex;
      flex-direction: column;
      padding-right: 15px;

      .title {
        font-size: $text-size-medium;
        font-weight: 500;
        color: $text-color;
        margin-bottom: 5px;
        height: fit-content;
      }

      .container-info-activity {
        display: flex;
        justify-content: start;
        height: fit-content;
        flex-flow: column;
        margin-bottom: 20px;

        .container-icon {
          display: inline-flex;
          padding: 5px;
          color: $text-color;
          &.hover {
            color: var(--primary);
          }
        }
        .accordion {
          .accordion-header {
            button {
              color: $primary;
              font-size: $text-size-little;
            }
          }
        }
      }

      .container-policies {
        display: flex;
        flex-direction: column;
        color: $text-color;
        font-size: $text-size-little;
      }
    }
  }

  &:hover {
    border-color: $primary;
  }
  .accordion-button {
    width: auto !important;
  }
  .accordion-item:last-of-type .accordion-button.collapsed,
  .accordion-item:last-of-type .accordion-button.collapsed {
    border-top-left-radius: inherit !important;
    border-top-right-radius: inherit !important;
  }
  .accordion-button:focus {
    box-shadow: initial !important;
  }

  .rate-discount {
    color: $success;
    display: flex;
    align-items: center;
    font-size: 17px;
    margin-top: 10px;
  }
}

@media (max-width: $md) {
  .container-card-activity {
    .card-body {
      flex-flow: column;

      .container-left {
        .title {
          font-size: $text-size;
        }

        .container-info-activity {
          margin-bottom: 0;
          .accordion {
            .accordion-header {
              button {
                font-size: $text-size-little;
              }
            }
          }
        }
      }
    }
  }

  .container-card-room {
    flex-direction: column !important;
    height: initial !important;
  }
}

@media (min-width: $md) and (max-width: $lg) {
  .container-card-room {
    .card-body {
      .container-left {
        .container-card-title {
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
        }
      }
    }
  }
}
