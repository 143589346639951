@import "../../../../theme/variable-switch.scss";

.div-filters {
  display: flex;
  gap: 3rem;
  padding-bottom: 10px;

  button.calendar-button-render-input.w-100.btn.btn-Light {
    border: 1px solid #ced4da;
  }
}

.col-filters {
  padding-bottom: 0;
  display: inline-block;
  align-self: flex-end;
}

.dropdown-filter {
  width: 200px;
}


.label-table {
  font-weight: 600;
  font-size: $text-size;
  margin-bottom: 0;
}

.btn-table {
  padding-top: 11px;
  padding-bottom: 11px;
}

@media (max-width:$md) {
  .div-filters {
    display: grid;
    margin-bottom: 2rem;
  }

  .col-filters {
    width: 100%;
    margin-bottom: -2rem;

    .dropdown-filter {
      width: 100%;
    }

    .my-reservations button.p-button.p-component.btn-table {
      width: 100%;

      .p-button .p-button-icon-left {
        display: none;
      }

    }
  }

}