@import "../../../../../theme/variable-switch.scss";

@import "../../../selects/SelectedCount/components/selectedCountStyle.scss";
@import "../../../../ui/BoxSelectPeopleSearch/components/boxSelectPeopleSearchStyle.scss";
@import "../../../../ui/GroupSelectPersons/components/groupSelectPersonsStyle.scss";

.search-room-button-render-input {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 48px;
    min-width: 230px;
    border-color: var(--bs-gray-500);
    border-radius: $border-radius-btn;
    background-color: field;
    &:hover, &[aria-describedby*="popover"] {
        border-color: var(--bs-gray-500);
        background-color: $backgroundBox;
    }
    span {
        font-size: $font-input;
    }
    svg {
        margin-right: 10px;
    }

    .pax-position-top{
        position: absolute;
        font-size: $text-size-small;
        top: 7%;
        left: 38px;
    }
}

.popover-search-room-button {
    max-width: initial;
    .popover-body {
        padding: 15px;
        max-width: initial;
        min-width: 300px;
    }
}

@media (max-width: $sm) {
    .paxes-modal{
        .popover{
            border: none;
        }
        .mobile-button{
            button{
                width: 100%;
            }
        }
    }
}