@import "../../../../theme/variable-switch.scss";

.button-toggle-triple {
	border-color: white;
	background-color: none !important;
	border-color: none;

	.icon-toggle {
		margin-right: 0.41rem;
	}

	.container-icons-toggle {
		display: flex;
		vertical-align: middle;
		align-items: center;
		justify-content: center;
		* {
			display: flex;
		}
	}

	.btn-check:checked+.btn,
	:not(.btn-check)+.btn:active,
	.btn:first-child:active,
	.btn.active,
	.btn.show {
		background: $background-page-list;
		border-color: $border-page-list;
		color: $color-page-list-active !important;
		font-weight: $font-weight-page-list;

		.icon-toggle {
			color: $color-page-list-active !important;
		}
	}

	label.toggle-sx.btn.btn-primary {
		background: $background-page-list;
		color: $color-page-list;
		border-color: $border-page-list;
	}

	.btn-primary {
		background-color: $button-background;
		border-color: $border;
		color: $textWhite;
		font-size: $font-button-toggle;
	}
}