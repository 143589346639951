@import "../../../../theme/variable-switch.scss";
@import "../../../../components/ui/DrawerFilter/components/DrawerFilter.scss";
@import "../../../../components/form/inputs/CheckBox/ListCheckBox/components/listCheckBox.scss";

.filter-canvas, .offcanvas-backdrop{
	z-index: 1061;
}

.modal-map{
	.modal-body{
		padding: 0;
		overflow: none;

		.button-filter-hotels{
			position: fixed;
			top: 80px;
			left: 10px;
			width: auto;
			&.only-icon{
				top: 73px;
				padding: 11px;
			}
		}
	}
}

.button-more-hotels,
.button-filter-hotels {
	color: $textWhite;
	align-items: center;
	width: 200px;
	z-index: 20;
	display: flex;
	justify-content: center;
	gap: 5px;
	&:hover {
		color: $textWhite;
	}
	&.button-float-invert {
		position: fixed;
		top: 50%;
		left: -85px;
		transform: rotate(-0.25turn);
		@media (max-width: $md) {
			display: none;
		}
	}
}

.row {
	--bs-gutter-x: none !important;
}

span.me-1.text-nowrap.order-filter {
	font-size: $text-size-low;
	font-weight: $font-weight-page-list-normal;
}

.total-hotel {
	font-size: $text-size-low;
	font-weight: $font-weight-page-list-normal;
}


.list-filters {
	margin-top: 12px;
	margin-bottom: 12px;
	display: flex;
	align-items: center;
	gap: 10px;

	.col {
		display: flex;
		align-items: center;
	}

	.container-views {
		justify-content: center;

		.button-toggle-triple {
			gap: 10px;

			.btn {
				padding: 0 !important;
			}
		}
	}

	.container-filter-orden {
		justify-content: end;
	}
}

.chip-container {
	padding: 0 8px;
}

@media (max-width: $lg) {
	.chip-container {
		padding: 0 23px;
	}
	
}

@media (max-width: $md) {
	.list-filters {
		justify-content: space-between;

		.container-views {
			order: 1;
			flex-basis: 100%;
		}

		.container-filter-orden {
			order: 2;
			justify-content: start;

			.dropdown-menu.show {
				right: auto !important;
			}
		}

		.container-count-results {
			order: 3;
			justify-content: end;
		}
	}
	.chip-container {
		padding: 0 5px;
	}
}

@media (max-width: $sm) {
	.chip-container {
		padding: 0 !important;
	}
}

@media (max-width: $xs) {
	.button-filter-hotels.button-float-invert {
		width: auto;
		left: -41px;
		top: 60%;
		padding-left: 25px;
		padding-right: 25px;
	}
}

@media (max-width: 575px) {
	.chip-container {
		max-width: 19rem;
		margin: auto;
		padding: 0 5px !important;
	}
}


button.swal2-confirm.swal2-styled {
  border-color: none !important;
}