@import "../../../../theme/variable-switch.scss";

.container-box-reservation-flight{
    .your-reservation{
        box-shadow: 0 0.13rem 0.5rem 0 rgb(0 0 0 / 6%), 0 0.06rem 0.13rem 0 rgb(0 0 0 / 8%);
        border-radius: 12px;
        background: white;
        border-radius: 12px 12px 0 0;
        h3{
            font-size: 18px;
            background: rgba(0,0,0,.02);
            box-shadow: inset 0 -1px 0 0 rgb(0 0 0 / 5%);
            border-radius: 12px 12px 0 0;
            padding: 12px 15px;
            margin-bottom: 0;
            font-weight: 600;
        }
        .selectedFareType{
            div{
                font-size: $text-size-little;
                font-weight: 700;
                line-height: 17px;
                padding: 4px 15px;
                border-left: 2px solid; 
            }
            .regularBasic{
                background: $backgroundRegular;
                color: $flightRegular;
                border-color: $flightRegular;
            }
            .vclubBasic{
                background: $backgroundClub;
                color: $flightClub;
                border-color: $flightClub;
            }
        }
        .content{
            padding: 15px;
            .title{
                font-size: $text-size-little;
                line-height: 17px;
            }
            .details{
                font-size: $text-size-small;
                line-height: 15px;
                border-radius: 10px;
                padding: 2px 4px;
                color: $flightRegular;
                cursor: pointer;
            }
            .flightSelected{
                margin: 8px 10% 12px;
                text-align: center;
                position: relative;
                .airport{
                    font-size: $text-size-aux;
                    line-height: 29px;
                    font-weight: 700; 
                }
                .time{
                    font-size: $text-size-small;
                    line-height: 15px;
                    color: rgba(0,0,0,.6); 
                }
                .separator{
                    background: black;
                    position: absolute;
                    width: 20px;
                    height: 2px;
                    top: 35%;
                    left: 50%;
                    margin-left: -10px;
                }
            }
            .date{
                font-size: $text-size-small;
                line-height: 15px;
            }
        }
    }
    .your-reservation:not(.noflight) {
        border-radius: 12px;
    }
    .item{
        box-shadow: 0 0.13rem 0.5rem 0 rgb(0 0 0 / 6%), 0 0.06rem 0.13rem 0 rgb(0 0 0 / 8%);
        border-radius: 12px;
        background: white;
        padding: 0 15px;
        margin-top: 8px;
    }
    .noflight+ .Passengers{
        margin-top: 0;
        border-radius: 0 0 12px 12px;
    }
    .accordion-button:not(.collapsed){
        color: $text-color;
        background-color: transparent;
        box-shadow: none;
    }
    .accordion-button:focus{
        box-shadow: none;
    }

    .priceR{
        position: absolute;
        bottom: 0;
        width: 100%;
        left: 0;
        display: flex;
        align-items: baseline;
        justify-content: space-between;
        background-color: $backgroundBoxDark;
        padding: 20px;
        div{            
            font-weight: 600;
           font-size: $text-size-medium;
           &:first-child{
            font-size: $text-size;
           } 
        }
        
    }
}