@import "../../../../../../../src/theme/variable-switch.scss";

.acordion {
  border: none;

  .accordion-button:not(.collapsed) {
    background: transparent;
  }
  .btn-filter {
    font-size: $text-size-little;
    color: $text-color;
  }
  .btn-filter:hover {
    background: none;
    border: none;
    color: $textSecondaryColor;
  }
}
