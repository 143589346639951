@import "../../../theme/variable-switch.scss";

.container-aux-voucher {
  padding-top: 30px;
  padding-bottom: 30px;

  h2 {
    font-size: $text-size-aux;
    //font-weight: bold;
  }
  .title {
    font-size: $text-size-aux;
  }
  .container-subtitle {
    width: 100%;
    display: flex;
    flex-direction: row;
  }
  .container-p{
    word-wrap: break-word;
  }
  // .text-subtitle {
  //   color: $primary;
  // }

  .strong {
    font-weight: 700;
  }

  .border-bottom-solid {
    border-bottom: 1px solid $primaryLight;
  }

  .border-top-solid {
    border-top: 1px solid $primaryLight;
  }
  .backcolor {
    background-color: $backgroundBox;
  }

  .back-white {
    background-color: white;
  }

  .text-right-color {
    color: $error;
  }
  .container-text-duo {
    width: 100%;
    padding-top: 5px;
    padding-bottom: 5px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;

    .text-left {
      display: flex;
      color: $text-color;
      font-weight: 700;
    }
    .text-right {
      display: flex;
      font-weight: 500;
    }
  }

  .text-total {
    display: flex;
    font-size: 1.5rem;
    font-weight: 700;
  }
}

.voucher-container {
  margin-left: auto;
  margin-right: auto;

  .title-service-general {
    font-size: $text-size-big;
    padding-left: 30px;
    padding-right: 30px;
  }

  .voucher-card {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 30px;
    background-color: white;
    border-radius: 10px;
    border-color: $text-color;
    box-shadow: $box-shadow-card;

    .voucher-card-header {
      display: flex;

      .container-img-perfil {
        display: flex;
        flex-direction: column;

        .voucher-img-circle {
          height: 170px;
					object-fit: contain;
          border-radius: 10px;
        }
      }

      .container-rest-header {
        margin-left: 30px;
        padding-bottom: 30px;

        .container-title-stars-checks {
          display: flex;
          flex-direction: column;
          width: 80%;
          .container-stars {
            height: fit-content;
            line-height: 1px;
            flex-grow: 1;
            display: flex;

            .box-star {
              color: var(--primary);
              font-size: 1.5rem;
            }
          }

          .container-checkouts {
            display: flex;
            flex-direction: column;
          }
        }

        .container-reference {
          display: flex;
          flex-direction: column;
          width: 20%;
          justify-content: center;

          .container-text-duo {
            flex-direction: column;

            .text-left {
              padding-right: 5px;
              font-weight: 700;
            }
            .text-right {
              padding-right: 5px;
              font-weight: 500;
            }
          }
        }
      }
    }

    .voucher-card-body {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      .container-rooms {
        display: flex;
        width: 100%;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;
        padding-top: 30px;
        padding-bottom: 15px;
        // padding-top: 30px;
        // padding-bottom: 30px;

        .room {
          display: flex;
          flex-direction: column;
          width: 45%;
          margin-bottom: 30px;
          &.room-60 {
            width: 60%;
          }
          .container-text-duo {
            justify-content: flex-start;
            .text-left {
              width: 50%;
            }
            .text-right {
              width: 50%;
            }
          }
        }
      }

      .container-politics {
        display: flex;
        width: 100%;
        flex-direction: column;
        padding: 30px 15px;
        // background-color: $backgroundBoxDark;
        background-color: $backgroundBox;
        border-radius: 10px;
        margin-top: 30px;
        margin-bottom: 30px;
      }

      .container-incluid {
        display: flex;
        width: 100%;
        flex-direction: column;
        padding-top: 30px;
      }
    }
  }

  .voucher-card-extra {
    padding: 30px;
    border-radius: 10px;
    border-color: $text-color;
    box-shadow: $box-shadow-container;
    overflow: hidden;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    &.backcolor {
      background-color: $backgroundBox;
    }
    h3 {
      font-size: $text-size-medium;
    }
    .rateTua {
      width: 100%;
      justify-content: space-between;
      display: flex;
      padding: 20px 0px;
      border-bottom: 1px solid #ccc;
    }
  }

  .box-tittle-column-state {
    height: $height-sm;
    overflow: hidden;
    transition-duration: 3s, 2s;
    transition-delay: 2s, 3s;
  }

  .content-list-ameni {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: flex-start;
    .container-text-icon {
      justify-content: flex-start;
      width: 30%;
      margin-bottom: 10px;
    }
  }
}

@media (max-width: $md) {
  .content-list-ameni {
    margin-bottom: 0 !important;
  }
  .voucher-container .content-list-ameni .container-text-icon {
    width: 100%;
  }
  .container-aux-voucher {
    // padding-top: 15px;
    // padding-bottom: 15px;
    h2 {
      font-size: $text-size-medium;
    }
    .title {
      font-size: $text-size-medium;
    }
  }

  .voucher-container {
    .title-service-general {
      padding-left: 15px;
      padding-right: 15px;
      font-size: $text-size-little;
    }
    .voucher-card {
      padding: 30px 15px;

      .voucher-card-header {
        flex-direction: column;

        .container-img-perfil {
          padding-bottom: 30px;
          flex-direction: row;
          margin-left: auto;
          margin-right: auto;

          .voucher-img-circle {
            height: 100%;
            width: 100%;
          }
        }

        .container-rest-header {
          margin-left: 0;
          padding-bottom: 30px;
          flex-direction: column;

          .container-title-stars-checks {
            flex-direction: column;
            width: 100%;
            margin-bottom: 20px;

            .container-stars {
              margin-bottom: 20px;
            }
          }

          .container-reference {
            width: 100%;

            .container-text-duo {
              flex-direction: column;

              .text-left {
                padding-right: 5px;
                font-weight: 700;
              }
              .text-right {
                padding-right: 5px;
                font-weight: 700;
              }
            }
          }
        }
      }

      .voucher-card-body {
        .container-rooms {
          flex-direction: column;

          .room {
            width: 100%;
            &.room-xs-100 {
              width: 100%;
            }
            .container-text-duo {
              justify-content: flex-start;
              .text-left {
                width: 50%;
              }
              .text-right {
                width: 50%;
              }
            }
          }
        }

        .container-politics {
          display: flex;
          width: 100%;
          flex-direction: column;
          padding: 30px 15px;
          background-color: $backgroundBox;
          border-radius: 10px;
          margin-top: 30px;
          margin-bottom: 30px;
        }

        .container-incluid {
          display: flex;
          width: 100%;
          flex-direction: column;
          padding-top: 30px;
        }
      }
    }

    .voucher-card-extra {
      padding: 30px;
      border-radius: 10px;
      border-color: $text-color;
      box-shadow: $box-shadow-container;
      overflow: hidden;
      background-color: white;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
  }
}

@media (min-width: $md) and (max-width: $lg) {
  .container-img-perfil {
    justify-content: center;
  }

  .voucher-container {
    .title-service-general {
      padding-left: 0;
    }
    .voucher-card {
      .voucher-card-header {
        .container-rest-header {
          flex-direction: row;
          .container-title-stars-checks {
            flex-grow: 1;
            width: fit-content;
          }
          .container-reference {
            flex-grow: 0;
            white-space: nowrap;
            width: fit-content;
          }
        }
      }
    }
  }
}
