@import "../../../../theme/variable-switch.scss";
.skeleton-header {
  display: flex;
  cursor: wait;
  height: 91px;
  flex-wrap: wrap;
  align-content: center;
  justify-content: space-around;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14),
    0 1px 10px 0 rgba(0, 0, 0, 0.12);
  margin-bottom: 2rem;
  .nav-title {
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    justify-content: space-around;
    gap: 1rem;
  }

  @media (max-width: $sm) {
    justify-content: space-between !important;

    .nav-mobile {
      display: none;
    }
    .logo-skeleton {
      width: 7rem !important;
    }
    .nav-right {
      width: 1.7rem !important;
      height: 1.7rem !important;
    }
  }
}

.skeleton-body {
  cursor: wait;
  display: flex;
  justify-content: center;
  margin-top: 2rem;
  .container-body {
    width: 90% !important;
    height: 11rem !important;
    overflow: hidden;
  }

  .container-body-large {
    width: 90% !important;
    height: 21rem !important;
    overflow: hidden;
  }
}
.container-loading {
  width: 100%;
  height: 100%;
  position: relative;
  min-height: 100vh;
  display: grid;
  justify-content: center;
  align-items: center;
}
.row-container {
  gap: 1rem;
  text-align: center;
  justify-self: center;
  .text-skeleton {
    color: $text-color;
    font-size: 1rem;
  }
}

.loadings {
  height: 100%;
  display: flex;
  gap: 1rem;

  color: $text-color !important;
}

.spinner-border.loadings {
  margin: 1rem;
  color: $text-color !important;
  height: 4rem;
  width: 4rem;
}
