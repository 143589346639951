@import "../../../../theme/variable-switch.scss";

//----- End StoryBook -----
// .container-max-width {
//   max-width: 300px;
// }
//----- Fin StoryBook -----
.toolTip-list {
	.container-text-icon {
		justify-content: flex-start;
		font-size: $text-size-small;
	}
}

.tooltip {
	z-index: 1;
	background-color: $backgroundBoxSecondaryDark;
}

.icon-promotion {
	font-size: $text-size-low;
	width: auto;
	height: auto;
	position: absolute;
	z-index: 2;
	bottom: 15px;
	left: 15px;

	.porcen {
		display: none !important;
		position: absolute;
		color: white;
		font-size: $border-radius;
		left: 20%;
		bottom: 2px;
	}
}

.card-content {
	.box-title {
		display: flex;
		flex-direction: column;
		justify-content: space-between;

		.box-service-descriptions {
			p {
				font-size: $font-card-text;
			}
		}
	}

	.box-title-content {
		display: -webkit-box;
		height: auto;
		line-height: 1;
		-webkit-line-clamp: 1;
		-webkit-box-orient: vertical;
		overflow: hidden;
		text-overflow: ellipsis;

		.box-title-content-title {
			font-size: $font-card-tittle;
			margin: 0;
			color: $text-card-color !important;
			font-weight: 700;
		}

		.box-title-content-title:hover {
			color: $text-card-color-hover !important;
		}
	}

	.box-content {
		display: flex;
		justify-content: space-between;
		align-items: center;
		min-height: 28px;
	}

	.divider-horizontal {
		color: var(--bs-gray-500);
		margin-bottom: 16px;
		margin-top: 0;
	}

	.container-stars {
		flex-grow: 1;
		display: flex;
		color: $primary;
	}

	.container-categories {
		display: flex;
		white-space: nowrap;
		overflow: hidden;

		span {
			color: $primary;
			font-size: calc($font-card-text - 2px);
			white-space: nowrap;
			text-overflow: ellipsis;
			overflow: hidden;
		}
	}

	.container-flex-address {
		text-align: right;
		white-space: nowrap;
		overflow: hidden;

		.container-address {
			display: block;
			margin: 0;
			text-overflow: ellipsis;
			overflow: hidden;
		}
	}

	.box-star {
		color: var(--primary);
		font-size: $font-card-content-icon;
	}

	.box-price {
		margin-top: 0px;
		padding-top: 0px;
		text-align: end;
		white-space: nowrap;
	}

	.container-flex-text {
		color: $text-colorLight;
		display: contents;
		align-items: baseline;
	}

	.container-discount {
		color: $success !important;
		display: block;
	}

	.container-mealplan {
		display: block;
		line-height: 1;

		span {
			font-size: $font-card-text;
			font-weight: bold;
		}
	}

	.container-flex-icon {
		color: $text-colorLight;
		display: inline-flex;
		align-items: baseline;
	}
}