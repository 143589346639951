@import "../../../../theme/variable-switch.scss";

.label-overlay{
    width: 100%;
    font-weight: 700;
}

.label-overlay-text {
    border-top-right-radius: $border-radius;
    border-bottom-left-radius: $border-radius;
    background: $primary;
    color: $textWhite;
    font-size: $font-card-tag;
    padding: 7px;

    svg {
        font-size: $font-card-icon;
    }
}

