//@import "./vars";
@import "./variable-switch.scss";

:root {
  --primary: #{$primary}; //#f37021
  --primary-light: #{$primaryLight};
  --primary-dark: #{$primaryDark};
  --secondary: #{$secondary}; //white
  --secondary-light: #{$secondaryLight};
  --secondary-dark: #{$secondaryDark};
  --background: #{$background};
  --background-light: #{$backgroundLight};
  --background-booking: #{$backgroundBox};
  --background-secondary: #{$background-secondary};
  --font-base: #{$fontfamily};
  --font-size: #{$text-size};
  --text-color: #{$text-color};
  --text-color-secondary: #{$textSecondaryColor};
  --text-color-disable: #{$textdisableColor};
  --autocomplete-suggestions-zindex: 1047;
}

body {
  background-color: var(--background);
  color: var(--text-color);
  font-family: $fontfamily !important;
  font-size: var(--font-size);
}

html,
body,
#root {
  height: 100%;
  font-family: $fontfamily !important;
}

body * ::-webkit-scrollbar,
html * ::-webkit-scrollbar {
  /*   background: #e5e5e5; */
  border-radius: 6px;
  height: 8px;
  width: 8px;
}

body * ::-webkit-scrollbar-thumb,
html * ::-webkit-scrollbar-thumb {
  background: $primary !important;
  border-radius: 6px;
}

.p-inputtext {
  font-family: $fontfamily !important;
}

.search-box-row.flex-md-nowrap.row {
  font-family: $fontfamily !important;
}

.container-flex {
  display: flex;
  flex-direction: column;

  &.item-row {
    flex-direction: row;
  }

  &.item-align-center {
    align-items: center;
  }

  .item-flex-grow {
    flex-grow: 1;
  }
}

.container-site,
.son-layout {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100%;
  height: 100%;
}

.header {
  color: $header-color !important;

  .btn-menu-toggle {
    color: $primary !important;

  }

  a.dropdown-item:active {
    background: $primary !important;
    color: $textWhite !important;
  }

  .btn-check:checked+.btn,
  :not(.btn-check)+.btn:active,
  .btn:first-child:active,
  .btn.active,
  .btn.show {
    background: transparent !important;
    font-weight: 500;
    color: $primary;
  }

  .nav-item.dropdown .dropdown-toggle {
    color: $header-color !important;

    &:hover {
      color: $header-color-hover !important;
    }
  }

  .a {
    color: $header-color !important;
    font-size: $ft-header;
  }

  .button-logout {
    color: $header-color !important;

    &:hover {
      color: $header-color-hover !important;
    }
  }
}

.header .nav-items .navlink-color {
  color: $header-color !important;
}

.header .nav-items .navlink-color:hover {
  color: $header-color-hover !important;
}

.header .lang {
  color: $header-color !important;

  &:hover {
    color: $header-color-hover !important;
  }
}

.footer {
  .footer-container-colums .footer-logo {
    object-fit: contain;
    max-width: 100%;
  }
}

.container-booking-fluid .container-booking-fluid-content .button-input-custom,
.container-booking-fluid-hotel .container-booking-fluid-content .button-input-custom {
  background: $background !important;
  color: $text-color !important;
  border: none;
  &:hover {
    background: $background !important;
    color: $text-color !important;
  }
}

.p-inputtext:enabled:focus {
  box-shadow: none;
}

.form-check-input:focus {
  box-shadow: none;
}

.search-box-service-hotel,
.search-box-service-tour,
.search-box-service-activity,
.search-box-service-transfer,
.search-box-service-shutter {
  background-color: $backgroundBoxSecondary !important;

  // background: red;
  &.box-sticky {
    position: sticky;
    top: -1px;
    z-index: 10;
  }

  &.box-fixed {
    position: fixed;
    top: 0px;
    z-index: 10;
    width: 100%;
  }

  .booking-search-information {
    color: $search-box-color !important;
  }

  .booking-engine {
    background-color: transparent;
    border: 0;
    box-shadow: none;

    h1,
    h2,
    .h2 {
      color: $search-box-color !important;
    }
  }

  .breadcrumb {
    font-size: $text-size-little;
    margin-bottom: 5px;

    .breadcrumb-item {
      color: $breadcrumb-color-page-list !important;

      &.active {
        color: $breadcrumb-active-color-page-list !important;
      }
    }
  }
}

.bg-light {
  background-color: var(--background-light);
}

.breadcrumb .breadcrumb-item:nth-child(2) {
  color: var(--bs-link-color) !important;
}

.rate-top {
  color: $text-color;
  font-size: $text-size-medium;
  font-weight: 500;
  justify-content: end;

  .box-rate-top {
    display: flex;
    flex-flow: column;
    justify-content: flex-end;
    align-items: end;

    .item-rate-top {
      display: flex;
      flex-flow: column;
      align-items: end;
      margin-bottom: 4px;

      .text-rate-top {
        font-size: 11px;
        display: block;
        line-height: 10px;
        margin: 4px 0;
      }
    }

    .rate-from {
      font-size: $text-size-small;
      font-weight: 400;
    }
  }
}

// botones
.search-box button.btn-book-submit.btn.btn-primary {
  background-color: $button-background !important;
  color: $button-text-color !important;
  border: none;

  &:hover {
    background-color: $button-background-hover !important;
    color: $button-text-hover !important;
  }
}

button.btn.btn-primary {
  &.cleaner-btn {
    &:active {
      background-color: $primary !important;
      color: $button-text-hover !important;
    }
  }
}

#body-render {
  flex-grow: 1;
}

//TODO leo los elimino, por que se uso los componentes, revisar
//@import "../components/headers/Header/components/Header.scss";
@import "../components/footers/Footer/components/Footer.scss";

.card-checkout-paxes {
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 0px 1px 0px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%),
    0px 1px 8px 0px rgb(0 0 0 / 12%);

  .card-title {
    .title {
      font-size: $font-card-paxes-reservation-title;
      font-weight: $font-weight-bold;
    }

    p {
      font-size: $font-card-paxes-reservation-title-description;
    }
  }

  .card-text {
    >.description {
      //font-weight: $font-weight-bold;
      text-align: justify;
      font-size: $font-card-paxes-reservation-description;
    }
  }

  .content-pax {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .pax-info {
      display: flex;
      flex-direction: column;

      .title {
        font-weight: $font-weight-bold;
        font-size: $font-card-paxes-reservation-pax-title;
      }

      .description {
        font-size: $font-card-paxes-reservation-pax-description;
      }
    }

    .pax-rate {
      font-weight: $font-weight-bold;
    }
  }
}

.alert-checkout-reservation-message {
  display: block;

  .alert-checkout-reservation-message-body {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .alert-danger {
    .btn {
      background: white;
      color: $error;
      border-color: $error;

      &:active {
        background: $error !important;
        border-color: $error !important;
        color: white;
      }
    }
  }

  .alert-success {
    .btn {
      background: white;
      color: $success;
      border-color: $success;

      &:active {
        background: $success !important;
        border-color: $success !important;
        color: white;
      }
    }
  }
}

.card-overlay-middle .text {
  .title {
    height: 24px;
  }

  p {
    height: 70px;
  }
}

.p-autocomplete {
  &.p-inputwrapper-focus {
    input {
      border-color: none !important;
      outline: 0;
    }
  }
}

@media (max-width: $sm) {
  body {
    font-size: $text-size-little;
  }

  .search-box-service-hotel {
    &.box-sticky {
      z-index: 102;
    }
  }

  .rate-top {
    justify-content: space-between;
    position: fixed;
    /* width: 100%; */
    left: 0;
    right: 0;
    padding: 10px 20px;
    height: 110px !important;
    z-index: 100;
    background: $textWhite;
    border-top: 1px solid $primary;
    color: $text-color;
    bottom: 0;
  }

  .modal {
    .modal-header {
      .modal-title {
        font-size: $text-size-medium;
      }
    }
  }

  .header .style-nav-center {
    min-height: 70px !important;
  }

  .btn-check:checked+.btn,
  :not(.btn-check)+.btn:active,
  .btn:first-child:active,
  .btn.active,
  .btn.show {
    background: transparent !important;
    border: none;
  }
}

.swal2-modal .swal2-styled.swal2-confirm:focus {
  box-shadow: none;
}

.dropdown-menu {
  border-radius: 8px !important;
}

.top-destination-container.card {
  background: transparent;
  border-radius: $border-radius;
}

@media (min-width: $sm) and (max-width: $lg) {
  .rate-top {
    display: none !important;
    height: 110px !important;
  }
}

@media (min-width: $sm) and (max-width: $md) {
  .search-booking-section {
    .title {
      display: none;
    }
  }

  .header {
    .style-nav-center {
      min-height: 66px;
    }
  }
}

@media (min-width: $lg) {
  .search-booking-section {
    .title {
      padding-top: 120px !important;
    }
  }
}

.header .dropdown-item {
  color: $text-color !important;

  &:hover {
    background: $primaryTransparent !important;
    color: $text-color !important;
  }
}

.header .dropdown-item.active {
  background: $primary !important;
  color: $textWhite !important;

  .search-booking-section {
    .title {
      padding-top: 120px !important;
    }
  }
}

div#autocomplete-list {
  span.label-icon {
    display: none !important;
  }
}

.p-autocomplete-panel svg {
  color: $primary;

}

.btn-check:checked+.btn,
:not(.btn-check)+.btn:active,
.btn:first-child:active,
.btn.active,
.btn.show {
  // background: transparent !important;
  border: transparent !important;
}

.card-overlay {
  z-index: -1;
}

#autocomplete-filter .mzp-autocomplete .btn-clear {

  color:  $primary;
  background-color: transparent;
}

.offcanvas-backdrop {

  z-index: 1040 !important;

}