@import "../../../../../theme/variable-switch.scss";

.basic-form {
  .input-group {
    margin-top: 20px;
    width: 100%;
    height: 50px;
    justify-content: center;
  }

  .form-check {
    font-size: $text-size-little;
    color: $text-color;
    height: 15px;
  }

  .btn {
    width: 90%;
    background: $color-background-b2b;
    border: none;
    border-radius: 4px;
    color: $textWhite;
    margin-top: 10px;
    display: grid;
    place-content: center;
  }

  .form-alert {
    height: 10px;
    margin-top: 0.3rem;
    color: $error !important;
  }

  .input-center {
    display: grid;
    place-content: center;
  }

  .captcha {
    transform: scale(0.9);
    margin: -1rem auto;
    margin-bottom: 1rem;
  }

  .form-message-success {
    height: 10px;
    margin-top: 0.3rem;
    color: $success !important;
  }
  @media (max-width: $sm) {
    .captcha {
      width: 100%;
      float: left;
    }
  }

  @media (max-width: $md) {
    width: 80% !important;

    .captcha {
      width: 70%;
    }

    .btn {
      width: 100%;
    }
    .input-captcha {
      margin-top: 1rem;
    }
  }
}

input-captcha.input-group {
  margin-top: 10px;
}
