@import "../../../../../theme/variable-switch.scss";

.mzp-autocomplete {
	position: relative;

	.input-group-text {
		background-color: initial;
		border: 0;
	}

	.autocomplete-btn-icon {
		background: blue;
	}

	input {
		min-width: 230px;
		height: 48px;
		border-width: 1px;
		border-color: var(--bs-gray-500);
		
		border-style: solid;
		border-image-width: 0;
		padding: 10px 35px;
		width: 100%;
		font-size: $font-input;
	}

	.p-autocomplete {
		width: 100%;

		&.p-inputwrapper-focus {
			input {
				border-color: none !important;
				outline: 0;
			}
		}
	}

	&.has-icon {
		.input-group-text {
			position: absolute;
			left: 0;
			top: 0;
			bottom: 0;

			svg {
				font-size: 1.25rem;
			}
		}

		input {
			padding-left: 40px;
		}
	}
}

.p-connected-overlay-enter-done {
	transform: none;
}

.p-component,
.p-component * {
	box-sizing: border-box;
}

.p-autocomplete-panel {
	position: absolute;
	top: 0;
	left: 0;
	overflow: auto;
	background: #ffffff;
	z-index: 1062 !important;
	border: 0 none;
	border-radius: 6px;
	box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);

	.p-autocomplete-items {
		margin: 0;
		list-style-type: none;
		padding: 0 0;

		.p-autocomplete-item {
			margin: 0;
			padding: 0.75rem 1.25rem;
			border: 0 none;
			background: transparent;
			transition: box-shadow 0.2s;
			border-radius: 0;
			cursor: pointer;
			white-space: nowrap;
			position: relative;
			overflow: hidden;
			&:hover {
				background: #e9ecef;
			}
		}
	}
	svg {
	color: $primary;
   }
}

#autocomplete-filter .mzp-autocomplete{
	position: relative;
	align-items: center;
	.btn-clear {
		position: absolute;
		right: 0px;
		&:hover{
			color: $primary;
		}
		&:active{
			border: none !important;
		}
	}
}