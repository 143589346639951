@import "../../../../theme/variable-switch.scss";

.background-wrapper { 
  .container-banner-video{
    margin-bottom: 0;
  }
  .container-banner-video .container-position{
    left: 1rem;
    right: 0;
    top: 30%;
    max-width: 100%;
  }

.box-text-banner-container{
  display: grid;
  color: $textWhite;
  .title{
    font-size: $text-size-big;
    font-weight: 700;
  }
  .subtitle{
    font-size: $text-size-aux;
    margin-bottom: 1rem;
  }
  .btn{
    background: $color-background-b2b;
    border: none;
    border-radius: 4px;
    max-width: 40%;
    color: $textWhite;
    font-size: $text-size-small;
  }
}
}

@media (min-width:$md) {
  .background-wrapper { 
    .container-banner-video .container-position{
      top: 50%;
      left: 2rem;
      max-width: 60%;    
    }
    .box-text-banner-container{
     
      .title{
        font-size: $text-size-mega;
        font-weight: 600;
      }
      .subtitle{
        font-size: $text-size-aux;
        margin-bottom: 1rem;
      }
      .btn{
        background: $color-background-b2b;
        max-width: 50%;
        font-size: $text-size-medium;
    }
     
    }

  }
}

@media (min-width:$xl) {
  .background-wrapper { 
    .container-banner-video .container-position{
      top: 50%;
      left: 26px;
      max-width: 60%;    
    }
    .box-text-banner-container{
    
      .title{
        font-size: $text-size-super;
        font-weight: 600;
      }
      .subtitle{
        font-size: $text-size-mega;
        margin-bottom: 1rem;
      }
     
    }

  }
}
