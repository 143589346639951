
@import "../../../../theme/variable-switch.scss";
.booking-engine-transfer {

    .search-room-button-render-input {
        min-width: 170px;
    }
    .calendar-button-render-input{
        min-width: 228px;
    }
    .mzp-autocomplete input{
        min-width: 200px;
    }
    
    .mzp-autocomplete{
        z-index: 1;
    }

    .groupinputDesti{
        flex-wrap: nowrap;
        .mzp-autocomplete:first-child{
            input{
                border-radius: 8px 0px 0px 8px !important;
            }
        }
        .mzp-autocomplete:last-child{
            input{
                border-radius: 0px 8px 8px 0px !important;
            }
        }
    }

    span.input-group-text.btn-round-mobile {
        border-radius: 2500rem !important;
        background: $textWhite;
        inline-size: 2.2rem;
        min-block-size: 2.2rem;
    }

    .input-group-text.btn-round-destopk {
        border-radius: 0.3rem !important;
        background: $textWhite;
        inline-size: 2.2rem;
        min-block-size: 2.2rem;
    }

    .btn-round-destopk {
        svg {
          transform: rotate(91deg); 
        }
    }


    @media (max-width:$md) {
    
        .booking-engine-col.btn-div.col{
            max-width: 129px;
        }
        .groupinputDesti {
            flex-wrap: wrap;
            justify-content: end;
            .mzp-autocomplete:first-child{
                input{
                    border-radius: 8px  !important;
                }
            }
            .mzp-autocomplete:last-child{
                input{
                    border-radius:  8px  !important;
                }
            }
        }
        .calendar-button-render-input{
            min-width: 100%;
            max-width: 100% !important;
        }  
    }

    @media (max-width:$lg) {
        .booking-engine-row{
            display: grid;
        }
        .booking-engine-col.booking-autocomplete.col{
            flex: 0 0 auto;
            width: 100%;
        }
        .booking-engine-col.btn-div.col{
            max-width: 100%;
        }

    }

}