@import "../../../../theme/variable-switch.scss";

.datatable-doc-demo .p-paginator .p-paginator-current {
    margin-left: auto;
}

.datatable-doc-demo .p-progressbar {
    height: 0.5rem;
    background-color: #d8dadc;
}

.datatable-doc-demo .p-progressbar .p-progressbar-value {
    background-color: #607d8b;
}

.datatable-doc-demo .p-datepicker {
    min-width: 25rem;
}

.datatable-doc-demo .p-datepicker td {
    font-weight: 400;
}

.reservation-list-datatable {
    .p-datatable.p-datatable-customers {
        .p-datatable-header {
            padding: 1rem;
            text-align: left;
            font-size: 1.5rem;
        }

        .p-paginator {
            padding: 1rem;
        }

        .p-datatable-thead>tr>th {
            text-align: left;
        }

        .p-dropdown-label:not(.p-placeholder) {
            text-transform: uppercase;
        }
    }
}

.header-table {
    display: flex;
    gap: 1rem;

    .dates {}

    .search {}

    .label-table {
        font-size: 15px;
        margin: 0;
        margin-bottom: 5px;
    }
}

button.p-button.p-component.p-splitbutton-defaultbutton {
    padding: 0 5px !important;
    font-size: 14px !important;
}

button.p-button.p-component.p-splitbutton-menubutton.p-button-icon-only {
    width: 2rem !important;
}