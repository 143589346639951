// card

.card-container {
  .card {
    border-radius: 1.4rem;
    border-color: #edeeef;
  }

  .card-img {
    border-radius: 1.4rem;
  }
}

.card-body {
  border-top: none !important;
}

.footer {
  .footer-container-colums .footer-logo {
    width: 100%;
    height: 120px;
  }

  .footer-container-colums .column-footer {
    display: flex;
    align-content: center;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    margin-bottom: 25px;
  }

  div#socialFooter a {
    background: linear-gradient(230deg,
        #fd921e 0%,
        #ff621d 48.44%,
        #e73a4e 97.92%);
    color: #ffffff !important;
    border: none;
    border-radius: 50%;
    padding: 0;
    display: flex;
    font-weight: 700;
    justify-content: center;
    height: 40px;

    &:hover {
      background: linear-gradient(230deg,
          #fcb66c 0%,
          #fc8958 48.44%,
          #df6d7b 97.92%);
      color: #ffffff !important;
    }
  }

  p.text-mini-left {
    font-weight: bold !important;
    color: hsl(18, 100%, 56%) !important;
    font-size: $text-size-low !important;
  }

  p.footer-title {
    font-weight: bold;
    margin-bottom: 0px;
  }

  a.footer-link.footer-item.nav-link {
    padding-top: 3px;
    padding-bottom: 0px;
    text-decoration: none !important;

    &:hover {
      color: $primary !important;
    }
  }
}

@media (max-width: 768px) {
  .offcanvas-header img {
    height: 20px !important;
    object-fit: contain !important;
  }

  .search-nexus .container-booking-fluid,
  .search-nexus .container-booking-fluid-hotel {
    margin-top: -2rem;
  }
}

// lista de resultados

.button-toggle-triple .btn-check:checked+.btn,
.button-toggle-triple .btn.active,
.button-toggle-triple .btn.show,
.button-toggle-triple .btn:first-child:active,
.button-toggle-triple :not(.btn-check)+.btn:active {
  font-weight: 100 !important;
}

.total-hotel,
span.me-1.text-nowrap.order-filter {
  font-size: 16px;
  text-transform: lowercase;
  font-weight: 500;
}

@media (min-width: $lg) and (max-width: $xxl) {

  .search-nexus .container-booking-fluid .container-booking-fluid-content,
  .search-nexus .container-booking-fluid-hotel .container-booking-fluid-content {
    padding: 0 !important;
  }

  .search-nexus .search-box button.btn-book-submit.btn.btn-primary {
    height: 35px;
    width: 35px;
  }
}

.card-sv-large {
  border-top-left-radius: 26px !important;
  border-bottom-left-radius: 26px !important;
}

.card-sv-large {
  border-radius: 26px !important;

  .card-content-img .card-img {
    border-radius: 26px !important;
  }
}

.card-container .card {
  border-radius: 26px !important;
  box-shadow: $box-shadow-card;
}

.container-card-room {
  border-radius: 26px !important;
}

.hotelroom .container-card-room {
  border-radius: 29px !important;
}

.hotelroom .container-card-room .container-card-img .card-img {
  border-radius: 28px !important;
}

.container-card-room .container-card-img .container-tag-selected-room {
  border-top-left-radius: 26px !important;
  border-top-right-radius: 26px !important;
}

@media (min-width: $lg) {
  .container-card-room .container-card-img .container-tag-selected-room {
    border-top-left-radius: 26px !important;
    border-top-right-radius: 26px !important;
    border-bottom-left-radius: 0px !important;
  }

  .hotelroom .container-card-room .container-card-img .card-img {
    border-radius: 28px !important;
  }
}

.box-select-people-search.pb-2.pt-2 {
  padding: 0px !important;
}

.box-select-people-search.pb-2 {
  padding-bottom: 0px !important;
}

.border-bottom.mb-3 {
  padding-bottom: 0.5rem !important;
}

.form-select {
  line-height: 1;
}

.fs-6 {
  margin-top: -0.2rem;
}

.box-select-people-search .container-select-years .box-years {
  margin: 4px 3px;
  padding: 0;
}

.search-box .btn-check:checked+.btn,
.search-box :not(.btn-check)+.btn:active,
.search-box .btn:first-child:active,
.search-box .btn.active,
.search-box .btn.show {
  background: $textWhite !important;
  border-color: #adacac !important;
}

.card-sv-large {
  .card-sv-content-info {
    height: 220px;
  }

  .card-content-img .card-img {
    height: 220px;
  }
}

.card-sv-large-b2b .card-content-img .card-img.placeholder-img {
  border-radius: $border-radius;
}

.container-booking-fluid,
.container-booking-fluid-hotel {
  background-position: center;
}

.mzp-autocomplete span.label-icon {
  color: $textWhite;
  z-index: 2;
}

.button-overlay .label {
  color: $textWhite;
  font-weight: 100;
}

@media (max-width: $sm) {
  .mzp-autocomplete span.label-icon {
    color: $text-color;
    z-index: 2;
  }

  .button-overlay .label {
    color: $text-color;
    font-weight: 100;
  }

  .search-box .calendar-button-render-input .label {
    color: $text-color;
    font-weight: 100;
    margin-top: 0rem !important;
  }
}

.mzp-autocomplete span.label-icon {
  z-index: 2;
  margin-left: 3px;
}

.autocomplete-list .suggestions li svg {
  color: $primary !important
}

.headerHome .btn-menu-toggle {
  color: $textWhite !important;
}


  
.search-nexus .headerHome .btn-menu-toggle {
 color: $textWhite !important;
}