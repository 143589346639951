@import "../../../../theme/variable-switch.scss";

.booking-search-information {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: $text-size;

  .bookig-edit-btn {
    display: flex;
    color: white;
    flex-direction: row;
    align-items: center;
    border-radius: $border-radius-btn;
    justify-content: center;
  }
  .text-button {
    margin-left: 5px;
  }
}
.divider {
  margin-right: 0.5rem;
  margin-left: 0.5rem;
}
@media (max-width: $md) {
  .booking-search-information {
    font-size: $text-size;
    svg {
      width: $text-size-medium;
      height: $text-size-medium;
    }
    .booking-engine {
      padding: 0 10px;
    }
    .booking-service-view {
      display: -webkit-box;
      //height: 26px;
      //line-height: 2;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .bookig-edit-btn {
      width: 50px;
      height: 50px;
      border-radius: 50%;
    }
  }
}

@media (max-width: $md) {
  .booking-search-information {
    svg {
      width: $text-size-medium;
      height: $text-size-medium;
    }
    .btn-mobile {
      .bookig-edit-btn {
        background: transparent !important;
        color: $primary !important;
        border: none;
      }
    }
  }
}
