@import "../../../../theme/variable-switch.scss";
//@import '../../../../components/boxes/BoxTitleCard/components/boxTitleCard.scss';
@import "../../../../components/label/DoubleLabel/components/doubleLabel.scss";

.container-aux-checkout {
  h2 {
    font-size: $text-size-aux;
  }
  p {
    margin-bottom: 0.5rem;
  }
  a {
    text-decoration: none;
  }

  .title {
    font-size: $text-size-aux;
  }

  .title-transfer {
    font-size: $text-size-low;
    margin-bottom: 0.5rem;
  }

  .title-transfer-icon {
    font-size: $text-size;

    svg {
      font-size: $text-size-low;
      color: $primary;
      padding-right: 5px;
    }
  }

  .title-service-general {
    font-size: $text-size-aux;
    padding-left: 0px;
    padding-right: 30px;
  }

  .box-summary {
    .box-title {
      .box-title-content {
        .box-title-content-title {
          font-size: $text-size-aux;
        }
      }
      .box-service-descriptions {
        justify-content: space-between;
      }
    }
  }
}

.card-container .box-title-content-title {
  color: $text-color;
  font-size: 22px;
  font-weight: 500;
}

.card-container {
  box-sizing: none;
}

.card-container:hover .card {
  box-shadow: none;
  transition: none;
}

.card-content .box-title {
  margin-bottom: 0.5rem;
}

.StripeElement {
  height: 40px;
  padding: 12px;
  border: 1px solid #ced4da;
  border-radius: 0.375rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  input {
    font-size: 1rem;
    font-weight: 400;
    border-radius: 6px;
    margin-bottom: 6px;
    padding: 12px;
    border: 1px solid rgba(50, 50, 93, 0.1);
    max-height: 44px;
    width: 100%;
    background: white;
    box-sizing: border-box;
  }
}

.checkout-spt-btn {
  color: white;
  &:active {
    background-color: $primary;
  }
}

.card-error {
  color: $error;
}

.checkout-spt-btn:disabled,
.checkout-spt-btn.disabled {
  cursor: no-drop;
  pointer-events: initial;
  display: flex;
  align-items: center;
  color: white;
}

.checkout-summary-form {
  order: 1;
}

.checkout-summary-ticket {
  order: 2;
}

.box-cards-payments-methods {
  display: block;
  padding-top: 10px;

  img {
    max-height: 100%;
    width: 40px;
    margin: auto;
    margin-right: 10px;
    margin-bottom: 3px;
  }
}

.input-group {
  select[id^="input-group-dropdown"] {
    width: 33%;
  }

  input[id^="phone"] {
    width: 65%;
    border-bottom-right-radius: 6px !important;
    border-top-right-radius: 6px !important;
    font-size: $text-size-little !important;
  }
}

.form-select-count-pax {
  button.btn.btn-outline-primary {
    display: flex;
    justify-content: center;

      flex-wrap: wrap;
      align-content: center;
    
  }
}

.container-select-years {
  .form-select {
    border-radius: 6px !important;
  }
}
.checkout-rate-details-comments {
  max-height: 200px;
  overflow-y: auto;
}

@media (min-width: $sm) and (max-width: $md) {
  .checkout-summary-ticket {
    order: 1;
  }

  .checkout-summary-form {
    order: 2;
  }
}

@media (min-width: $md) and (max-width: $lg) {
  .checkout-summary-ticket {
    order: 1;
  }

  .checkout-summary-form {
    order: 2;
  }
}

@media (min-width: $lg) and (max-width: $xxl) {
  .checkout-summary-ticket {
    order: 2;
    padding-left: 25px;
  }

  .checkout-summary-form {
    order: 1;
  }
}

@media (min-width: $xxl) {
  .checkout-summary-ticket {
    order: 2;
    padding-left: 25px;
  }

  .checkout-summary-form {
    order: 1;
  }
}

@media (max-width: $sm) {
  .container-aux-checkout {
    h2 {
      font-size: $text-size-medium;
    }

    .title {
      font-size: $text-size-medium;
    }

    .title-service-general {
      padding-left: 15px;
      padding-right: 15px;
      font-size: $text-size-little;
    }

    .box-summary {
      .box-title {
        .box-title-content {
          .box-title-content-title {
            font-size: $text-size-medium;
          }
        }
      }
    }
  }

  .checkout-summary-form {
    order: 2 !important;
  }

  .checkout-summary-ticket {
    order: 1;
  }

  .checkout-spt-btn {
    width: 100%;
    justify-content: center;
  }
}

textarea {
  resize: none;
}

button.swal2-confirm.swal2-styled {
  border-color: none !important;
}

p.textSR {
  word-wrap: break-word;
}

textarea.form-control {
  border-radius: $border-radius-btn;
}

.input-group select[id^="input-group-dropdown"] {
  border-top-left-radius: 6px;
  width: 33%;
  border-bottom-left-radius: 6px;
  border-top-right-radius: 0px !important;
  line-height: 1.5rem;
  font-size: 1rem !important;
  border-bottom-right-radius: 0px !important;
}

.headerCheckOut {
  .btn-menu-toggle {
    display: none;
  }
}
