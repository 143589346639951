@import "../../../../theme/variable-switch.scss";

.skeleton-voucher-card{    
    width: 100%;
    padding:30px 30px 0 30px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0px 0px 1px 0px rgb(0 0 0 / 20%),
      0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%);

      .border-bottom-solid {
        border-bottom: 1px solid $primaryLight;
      }

    .skeleton-voucher-card-header{
        display:flex;
    }

    .skeleton-voucher-card-img{
        width: 200px;
        height: 170px;
    }
    .skeleton-voucher-header-text{
        margin-left: 20px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        padding-bottom: 20px;

        .skeleton-voucher-header-text-left{
            padding: 0 30px 0 0;
            width: 80%;

            span{
                display: block;
                margin: 20px 5px;
            }
        }

        .skeleton-voucher-header-text-right{
            padding-top:20px;
            width: 20%;

            span{
                display: block;
                margin:10px 5px;
            }
        }
    }

    .whidth-body-first{
        width: 50%;
    }

    .skeleton-voucher-card-body{
        padding: 30px 0 30px 0;

        .skeleton-voucher-card-body-container{
            padding-top: 30px;
            display: flex;
            justify-content: space-between;
        }

        .skeleton-voucher-body-text-left, .skeleton-voucher-body-text-right{
            width: 50%;

            span{
                display: block;
                margin: 7px 0;
            }
        }
    }

}


@media (max-width: $sm){

    .skeleton-voucher-card { width: 100%;
        padding: 30px 15px 0 15px;
        .skeleton-voucher-card-header{
            flex-direction: column;
            .skeleton-voucher-card-img{
                width: 100%;
                height: 210px;
            }
            .skeleton-voucher-header-text{
                margin-left: 0;
                flex-direction: column;
            }
            .skeleton-voucher-header-text-left, .skeleton-voucher-header-text-right{
                width: 100%;
            }
        }
        .skeleton-voucher-card-body {
            width: 100%;

            .skeleton-voucher-card-body-container{
                width: 100%;
            }
        }
        .body-last{
            display:none;
        }
    }
}