@import "../../../theme/variable-switch.scss";

.nav-bar-container {
  background: $background;
  width: 100%;
  border-top: 3px solid $primary;
  display: grid;
  place-content: center;
  position: fixed;
  position: fixed;
  bottom: 0%;
  z-index: 50;
  font-weight: 100 !important;

  .button-toggle-triple label.toggle-sx.btn.btn-primary:active {
    border-radius: 0;
    background: transparent;
    border-radius: transparent;
    color: $color-page-list !important;
    font-weight: 100 !important;
    svg {
      color: $text-color !important;
    }
  }

  .button-toggle-triple .btn-check:checked+.btn,
  .button-toggle-triple :not(.btn-check)+.btn:active,
  .button-toggle-triple .btn:first-child:active,
  .button-toggle-triple .btn.active,
  .button-toggle-triple .btn.show {
    border-radius: 0 !important;
    background: transparent;
    border-radius: transparent;
    color: $color-page-list !important;
    font-weight: 100 !important;
    svg {
      color: $color-page-list !important;
    }
  }
}