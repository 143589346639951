@import "../../../../../theme/variable-switch.scss";

.calendar-button-render-input {
	display: flex;
	flex-direction: row;
	align-items: center;
	height: 48px;
	font-size: $font-input !important;
	border-color: var(--bs-gray-500);
	border-radius: $border-radius-btn;
	background-color: field;

	&:hover,
	&[aria-describedby*="popover"] {
		border-color: var(--bs-gray-500);
		background-color: $backgroundBox;
	}

	span {
		font-size: $font-input;
	}

	svg {
		margin-right: 10px;
	}
}

.popover-calendar-button {
	max-width: initial;

	.popover-body {
		padding: 0;
		padding-top: 15px;

		.rdrMonthAndYearWrapper {
			padding: 0;
			position: absolute;
			left: 0;
			right: 0;
			top: 0;
			height: 42px;
			z-index: 100;

			.rdrMonthAndYearPickers {
				display: none;
			}
		}

		.rdrDateDisplayWrapper {
			display: none;
		}

		.rdrMonth {
			.rdrMonthName {
				//color: var(--primary-dark);
				font-size: 15px;
				text-align: center;
			}

			.rdrWeekDays {
				.rdrWeekDay {
					//color: $calendarDark;
					font-size: 15px;
					//fontFamily: fontFamilyType.font
				}
			}

			.rdrDays {
				border: 1px solid var(--bs-gray-300);
				border-top: 0;

				.rdrDay {
					border-right: 1px solid var(--bs-gray-300);
					border-top: 1px solid var(--bs-gray-300);
					box-sizing: border-box;
					margin-left: 0;

					.rdrDayNumber {
						font-size: 15px;
					}

					.rdrStartEdge {
						background: $calendar;
					}

					.rdrInRange {
						background: $calendarLight;
					}

					.rdrEndEdge {
						background: $secondary;

						&.rdrStartEdge {
							background: $calendar;
						}
					}

					&.rdrDayHovered {
						background: $secondary !important;
						border-color: $secondary;

						span {
							color: white !important;
						}

						.rdrInRange+.rdrDayStartPreview+.rdrDayNumber {
							span {
								color: 1px solid $calendarDark !important;
							}
						}
					}

					.rdrStartEdge+.rdrDayNumber,
					.rdrEndEdge+.rdrDayNumber {
						span: {
							color: white !important;
						}
					}

					.rdrStartEdge+span+.rdrDayNumber,
					.rdrEndEdge+span+.rdrDayNumber {
						span: {
							color: white !important;
						}
					}
				}

				.rdrDay:nth-of-type(7n) {
					border-right: 0;
				}

				.rdrDay:nth-of-type(7n),
				.rdrDay:nth-of-type(6),
				.rdrDay:nth-of-type(13),
				.rdrDay:nth-of-type(20),
				.rdrDay:nth-of-type(27),
				.rdrDay:nth-of-type(34) {
					//TODO last days in the week
					background: var(--bs-gray-100);
					//background: theme.palette.basic.light
				}

				.rdrStartEdge,
				.rdrInRange,
				.rdrEndEdge,
				.rdrDayInPreview,
				.rdrDayStartPreview,
				.rdrDayEndPreview {
					border-radius: 0;
					top: 0;
					left: 0;
					right: 0;
					bottom: 0;
				}
			}

			.rdrDayPassive {
				.rdrDayNumber {
					display: none
				}
			}
		}
	}
}

@media (min-width:$md) {
	.popover-calendar-button {
		max-width: initial;

		.popover-body {
			padding: 0;
		}
	}
}