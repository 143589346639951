
@import "../../../../theme/variable-switch.scss";

.box-detail-flight{
    max-height: 500px;
    overflow-y: scroll;
    .desc-detail-flight{
        h6{
            font-size: $text-size;
            font-weight: 600;
        }
        span{
            font-size: $text-size-small;
            display: block;
            margin-bottom: 15px;
        }
        b{
            font-size: $text-size-little;
            display: block;
            margin-bottom: 15px;
        }
        border-bottom: 1px solid #ccc;
        margin-bottom: 20px;
    }
    .desc-timeline-flight{
        .info-tag-flight{
            h5{
                font-size: $text-size-little;
                margin-bottom: 15px;
            }
            span{
                font-size: $text-size-small;
                display: block;
            }
        }
    }
    .layover-details{
        background: $shadowclubbtn;
        display: inline-flex;
        width: 100%;
        color: $primary;
        align-items: center;
        padding: 10px;
        border-radius: 6px;
        margin: 15px 0 25px;
        display: flex;
        gap: 10px;
    }
    .info-timeline{
        position: relative;
        .timeline-vertical{
            border-left: 2px solid rgba(0,0,0,.1);
            height: 80px;
            position: absolute;
            top: 16px;
            left: 4px;            &::before{
                content: "";
                background: #000;
                display: block;
                width: 6px;
                height: 6px;
                border-radius: 50%;
                position: absolute;
                top: -2px;
                left: -4px;
            }
            &:after{
                right: -2px;
                top: 80px;
                content: "";
                background: #000;
                display: block;
                width: 6px;
                height: 6px;
                border-radius: 50%;
                position: absolute;
            }
        }
        .flight{
            margin-bottom: 30px;
            display: flex;
            align-items: flex-start;
            margin-left: 15px;
            gap: 10px;
            .desti-clock{               
                span{
                    display: block;
                    font-size: $text-size;
                }
                small{
                    font-size: $text-size-small;
                }
            }
        }
        
    }
}

@media (max-width: $lg) {
    .box-detail-flight{
        max-height: inherit;
    }
}