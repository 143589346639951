@import "../../../../theme/variable-switch.scss";

.box-service-rate {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-top: auto;
  margin-bottom: auto;

  .rate-from {
    font-size: 12px;
  }
  .rate-offe {
    font-size: $text-size-little;
    text-decoration: line-through;
  }
  .rate {
    font-size: $text-size-medium;
    color: $text-card-color;
    white-space: nowrap;
    line-height: normal;
    font-weight: $font-weight-bold;
    display: inline-block;
    margin-bottom: 0;
    text-transform: uppercase;
  }
  .rate:hover {
    color: $text-card-color-hover;
  }
  button.btn-reservar.btn.btn-primary {
    width: auto;
		min-width: 150px;
    @media (max-width: $sm) {
      min-width: 110px;
    }
  }
  .informations {
    font-size: $text-size-little;
    margin-bottom: 0px;
  }
  .overlay-container {
    font-size: $text-size-medium;

    .button-overlay {
      border: 0;
      border-radius: 0;
      padding: 0;
      height: initial;
      width: initial;
    }
  }

  button,
  .btn-reservation {
    margin-top: 10px;
    transition: 0.2s;
    font-size: $text-size;
    color: white;
    &:hover {
      color: white;
    }
  }

  &.bg-rate {
    border-radius: 1.5rem;
    background-color: $backgroundLight;
  }

  .trought {
    text-decoration: line-through;
  }
}

@media (max-width: $sm) {
  .box-service-rate {
    .rate {
      font-size: $text-size-medium;
    }
    .informations {
      font-size: $text-size-small;
    }
  }
}
