@import "../../../../theme/variable-switch.scss";

.box-tua-form {
  border: 2px solid $backgroundRegular;
}
.box-tua {
  font-size: $text-size-little;
  // padding: 24px 20px 20px;
  .box-title {
    color: $textBlack;
    font-weight: $font-weight-bold;
    font-size: $text-size-medium;
    margin-bottom: 1rem;
  }

  .box-tua-img {
    display: grid;
    place-content: center;
  }

  .tua-footer {
    padding: 10px;
  }

  .alert-tua {
    // display: inline-flex;
    background: rgba(211, 47, 47, 0.08);
    // border-radius: 8px;
    // padding: 0 15px;
    // align-items: center;
    color: #d32f2f;
    // height: 50px;
  }
  .form-check-input[type="checkbox"] {
    border-color: $backgroundRegular;
  }
}
