@import "../../../../theme/variable-switch.scss";

.card-container-map {
	a {
		text-decoration: none;
		color: initial;
	}

	.container-card-general {
		display: flex;
		flex-direction: column;
		width: 300px;
		overflow: hidden;
		.card-img {
			width: 100%;
			border-radius: $border-radius;
			object-fit: cover;
			object-position: center;
			height: 11rem;
		}
		
		.carousel-indicators {
			display: none;
		}

		.carousel-control-prev,
		.carousel-control-next {
			opacity: 0.7;

			&:hover {
				opacity: 0.8;
			}
		}

		.carousel-control-prev-icon,
		.carousel-control-next-icon {
			background-color: black;
			border-radius: 50%;
			background-size: 50% 50%;
			font-weight: 900;
			opacity: 0.7;

			&:hover {
				opacity: 0.9;
			}
		}

		.card-div {
			width: 100%;
			font-weight: 700;
			position: absolute;
			padding: 4px;
			z-index: 5;
			.card-label {
				position: absolute;
				left: 0;
				top: 0;
				background: $primary;
				color: $textWhite;
				font-size: $font-card-tag;
				padding: 10px;

				svg {
					font-size: $font-card-icon;
				}
			}
		}
	}

	.card-div-close {
		position: absolute;
		top: 7px;
		right: 7px;
		z-index: 1;
		.close-button {
			font-size: $text-size-small;
			border-radius: 50%;
			background-color: rgba(0,0,0,0.5);
			color: white;
			border: none;
			width: 26px;
			height: 26px;
		}

		.close-button:hover {
			background-color: $primary !important;
		}
	}

	.card-content {
		align-items: inherit;
		display: flex;
		flex-direction: column;
		height: 100%;

		.container-not-avalability{
			align-self: flex-end;
			justify-content: end;
		}
	}

	.card-content-button {
		background: $primary;
		align-items: center;
		color: $textWhite;
	}

	.box-item-text {
		font-size: $font-card-text-small;
		color: $textBlack;
		padding: 0;
		margin: 0;
		&.bold{
			font-weight: bold;
		}
	}

	.rate-without-discount {
		font-size: $font-card-without-rate;
		color: $text-colorLight;
		padding: 0;
		margin: 0;
	}

	.box-item-price {
		font-weight: $font-weight-bold;
		color: $text-card-color;
		font-size: $font-card-tittle;
		display: flex;
		justify-content: end;
		align-items: center;
		margin-bottom: 0;
	}

	.container-flex {
		flex-direction: inherit;
		justify-content: space-between;
	}

	.d-flex.justify-content-start.mt-2.box-ofer {
		display: flex !important;
		flex-direction: column;
	}

	.container-text-icon {
		display: flex;
		justify-content: flex-start;
	}

	.container-duration {
		display: flex;
		font-size: $font-card-text;
	}

	.icono {
		color: var(--primary) !important;
		padding-right: 5px;
	}

	&:hover {
		.box-title-content-title {
			color: var(--primary);
		}

		.box-item-price {
			color: var(--primary);
		}

		.container-discount {
			color: $success !important;
		}

		.container-flex-text-icon {
			color: var(--primary) !important;
		}
	}
}