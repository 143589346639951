@import "../../../../theme/variable-switch.scss";

.flightItemDetails {
  .fareTypes {
    /*   padding: 40px 30px 15px; */
    border-radius: 0 0 12px 12px;
    .mat-card {
      cursor: pointer;
      border: 1px solid rgba(0, 0, 0, 0.02);
      box-shadow: 0 2px 4px 0 rgb(146 132 132 / 3%), 0 1px 1px 0 rgb(0 0 0 / 4%);
      border-radius: 12px;
      position: relative;
      padding: 15px 16px 16px;
      min-height: 358px;
      &::before {
        content: "";
        border-radius: 12px 12px 0 0;
        height: 8px;
        width: 100%;
        display: block;
        position: absolute;
        top: -1px;
        left: 0;
      }
      .overlayGo {
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 1;
      }
      h3 {
        font-size: $text-size-aux;
        margin-bottom: 0;
        span {
          display: block;
        }
        .RegularSubtitle {
          line-height: 12px;
          font-weight: 400;
          font-size: $text-size-small;
        }
      }
      .benefitsList {
        list-style: none;
        margin: 0;
        padding: 0;
        min-height: 198px;
        li {
          min-height: 47px;
          margin-top: 16px;
        }
        li:first-child {
          margin-top: 0;
        }
        li {
          font-size: $text-size-small;
          .textpar {
            margin-bottom: 6px;
            display: inline-grid;
            .personalItemLabel {
              line-height: 15px;
              color: $background-secondary;
              .tooltip-bag {
                z-index: 2;
                position: relative;
              }
            }
          }
          .personalItemSubLabel {
            font-size: $text-size-mini;
            line-height: 12px;
            color: rgba(0, 0, 0, 0.6);
            margin-top: 2px;
            strong {
              font-size: $text-size-small;
            }
          }
          .disabled {
            color: rgba(0, 0, 0, 0.25);
            .icon-item {
              font-size: $text-size-small;
            }
            .personalItemSubLabel {
              color: rgba(0, 0, 0, 0.25) !important;
              font-size: $text-size-small;
            }
          }
        }
      }
      .rate-box {
        .preferential {
          height: 22px !important;
          text-align: center;
          font-size: $text-size-mini;
          line-height: 12px;
          padding-bottom: 12px;
          color: $background-secondary;
        }
        .priceDiscounted {
          margin-top: 0;
          padding-bottom: 5px;
          text-align: center;
          font-size: $text-size-small;
          line-height: 15px;
          color: $primary;
          text-decoration: line-through;
        }
        .price {
          text-align: center;
          font-size: $text-size-tall;
          font-weight: 700;
        }
      }
      button {
        width: 100%;
        max-height: 0;
        transform: scaleY(0);
        margin-top: 10px;
        .mat-button-focus-overlay,
        .mat-button-ripple.mat-ripple {
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          position: absolute;
          pointer-events: none;
          border-radius: inherit;
        }
        .mat-button-focus-overlay {
          opacity: 0;
          transition: opacity 0.2s cubic-bezier(0.35, 0, 0.25, 1),
            background-color 0.2s cubic-bezier(0.35, 0, 0.25, 1);
        }
        .mat-button-wrapper {
          line-height: 38px;
          font-size: $text-size-small;
        }
      }
      .btn-small {
        min-width: 100px;
        padding: 0 18px;
        line-height: 38px;
        font-size: $text-size-small;
        border-radius: 20px;
      }
      .mat-secondary {
        background: $btn-flight;
        color: $btn-flight-color;
      }
      .mat-secondary:not(:disabled):not(:focus):hover {
        background: $backgroundBoxDark;
        box-shadow: 0 8px 20px 0 $primaryLight;
      }
      &:not([disabled]):hover button {
        max-height: 40px;
        transition: max-height 0.2s ease-in;
        transform: scaleY(1);
        margin-top: 10px;
      }

      &:not([disabled]):hover {
        box-shadow: 0 2px 8px 0 rgb(0 0 0 / 6%), 0 20px 25px 0 rgb(0 0 0 / 8%);
        transform: translate(0, -20px);
        transition: top, ease-in-out, 0.5s;
        position: absolute;
        left: 10px;
        right: 10px;
        z-index: 1;
      }
      .disabled-container {
        position: absolute;
        padding-top: 8px;
        left: 0;
        height: 100%;
        top: 0;
        display: none;
        z-index: 2;
        .disabled-message {
          background: #fafafa;
          height: 100%;
          padding: 0 30px;
          font-size: $text-size-small;
          font-weight: 700;
          text-align: center;
          vertical-align: middle;
          align-items: center;
          display: flex;
          opacity: 0.85;
          cursor: default;
        }
      }
      .ribbon-container {
        position: absolute;
        overflow: hidden;
        top: 0px;
        right: -1px;
        width: 80px;
        height: 102px;
        &::before,
        &::after {
          position: absolute;
          z-index: -1;
          content: "";
          display: block;
          border: 5px solid transparent;
          border-top-color: transparent;
          border-left-color: transparent;
        }
        &::before {
          top: 0;
          right: 0;
        }
        &::after {
          bottom: 0;
          left: 0;
        }

        span {
          position: absolute;
          width: 121px;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 3px 0;
          background-color: $tag;
          box-shadow: 0 5px 5px rgb(0 0 0 / 20%);
          color: white;
          font-size: $text-size-small;
          /*text-transform: uppercase;
                    */
          text-align: center;
          left: -1px;
          top: 10px;
          transform: rotate(45deg);
          -webkit-transform: rotate(45deg);
          -moz-transform: rotate(45deg);
          -ms-transform: rotate(45deg);
          -o-transform: rotate(45deg);
        }
      }
    }
    .mat-card[disabled]:hover {
      border: none !important;
    }
    .mat-card[disabled]:hover .disabled-container {
      display: block;
    }
    .mat-card[disabled]::before {
      background: $colorOverLayer !important;
    }
    .mat-card[disabled] h3 {
      color: $colorOverLayer !important;
    }
    .mat-card[disabled] .item-benefists .personalItemLabel,
    .mat-card[disabled] .item-benefists .personalItemSubLabel,
    .mat-card[disabled] .item-benefists .icon-item,
    .mat-card[disabled] .rate-box .preferential,
    .mat-card[disabled] .rate-box .priceDiscounted,
    .mat-card[disabled] .rate-box .price {
      color: $colorOverLayer !important;
    }
    .mat-card[disabled] .ribbon-container {
      /*    z-index: 1; */
    }
    .mat-card[disabled] .ribbon-container span {
      background-color: $colorOverLayer !important;
    }
  }
  .fareTypeRegular {
    .mat-card.basic {
      h3 {
        color: $familyRegularBasic;
      }
      &:before {
        background: $familyRegularBasic;
      }
      &:hover {
        border: 1px solid $familyRegularBasic;
      }
    }
    .mat-card.classic {
      h3 {
        color: $familyRegularClassic;
      }
      &:before {
        background: $familyRegularClassic;
      }
      &:hover {
        border: 1px solid $familyRegularClassic;
      }
    }
    .mat-card.plus {
      h3 {
        color: $familyRegularPlus;
      }
      &:before {
        background: $familyRegularPlus;
      }
      &:hover {
        border: 1px solid $familyRegularPlus;
      }
    }
  }
  .fareTypeVClub {
    .mat-card.basic {
      h3 {
        color: $familyClubBasic;
      }
      &:before {
        background: $familyClubBasic;
      }
      &:hover {
        border: 1px solid $familyClubBasic;
      }
    }
    .mat-card.classic {
      h3 {
        color: $familyClubClassic;
      }
      &:before {
        background: $familyClubClassic;
      }
      &:hover {
        border: 1px solid $familyClubClassic;
      }
    }
    .mat-card.plus {
      h3 {
        color: $familyClubPlus;
      }
      &:before {
        background: $familyClubPlus;
      }
      &:hover {
        border: 1px solid $familyClubPlus;
      }
    }
  }
  .detail-baggage {
    margin-top: 40px;
    display: flex;
    justify-content: space-between;
    .btn-bagagge {
      font-size: $text-size-little;
      cursor: pointer;
      color: $primary;
    }
    .text-cabin {
      font-size: $text-size-small;
      line-height: 15px;
    }
  }
}

@media (max-width: $md) {
  .flightItemDetails .fareTypes .mat-card {
    margin-bottom: 20px;
    min-height: auto;
    .preferential-sm {
      margin-top: 20px;
      margin-bottom: 10px;
      font-size: $text-size-small;
      justify-content: space-between;
      span:first-child {
        color: $colorOverLayer !important;
        text-decoration: auto;
      }
      span {
        color: $primary;
        text-decoration: line-through;
      }
    }
  }
  .flightItemDetails .fareTypes .mat-card:not([disabled]):hover {
    position: relative;
    transform: none;
    right: 0;
    left: 0;
  }
  .flightItemDetails .fareTypeRegular .mat-card h3 {
    justify-content: space-between;
    span {
      font-size: $text-size-low;
    }
  }
}
