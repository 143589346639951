@import "../../../theme/variable-switch.scss";
@import "../../../components/galleries/Collage/components/collage.scss";
@import "../../../components/bookings/BookingInformation/Components/bookingInformationStyle.scss";
@import "../../../components/boxes/BoxDetailSeviceTitleRate/components/style.scss";


h3{
	font-weight: normal !important ;
}
h4{
	font-weight: normal !important;
}
h5{
	font-weight: normal !important ;
}
.box-detail-service-title-rate {
	.container-page{
		background-color: $backgroundLight;
	}
	.box-title {
		padding-right: 15px;

		.container-stars {
			.box-star {
				color: var(--primary);
			}
		}

		.container-categories {
			color: var(--primary);
			margin-bottom: 10px;
		}
	}
}

.box-hashotel-column {
	column-count: 3;

	.box-hashotel-item {
		text-align: left;
		margin-bottom: 15px;

		.container-text-icon {
			display: block;
		}
	}
}

.box-tittle-column-state {
	height: $height-sm;
	overflow: hidden;
	transition-duration: 3s, 2s;
	transition-delay: 2s, 3s;
}

.box-tittle-column-state-about-hotel {
	height: 125px;
	transition-duration: 3s, 2s;
	transition-delay: 2s, 3s;
}

.details-title-description {
	font-size: $text-size-aux;
	margin-bottom: 24px;
	margin-top: 24px;
}

.container-information-service-bg {
	border-radius: $border-radius;
	box-shadow: $box-shadow-container;

	.details-container-grid {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		grid-template-rows: auto;
		grid-auto-rows: 0;
		overflow: hidden;

		&.expand {
			grid-auto-rows: auto;
		}

		.box-hashotel-item:nth-child(3n+1) {
			order: 1;
		}

		.box-hashotel-item:nth-child(3n+2) {
			order: 2;
		}

		.box-hashotel-item:nth-child(3n) {
			order: 3;
		}
	}
}

.box-info-hide {
	transition: 1s;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 3;

	&.box-info-show {
		-webkit-line-clamp: initial;
	}
}

.box-info-show {
	-webkit-line-clamp: initial;
}

.button-see-more {
	margin-top: 20px;
	margin-left: auto;
	margin-right: auto;
	display: block;

	&:hover {
		color: white;
	}
}

@media (max-width: $sm) {
	.box-hashotel-column {
		column-count: 1;
	}

	.collage {
		.container-duo {
			display: none;
		}
	}

	.details-title-description {
		font-size: $text-size-medium;
	}

	.container-information-service-bg {
		border-radius: $border-radius;

		h5 {
			font-size: $text-size-low;
		}

		.details-container-grid {
			grid-template-columns: repeat(1, 1fr);
			grid-template-rows: repeat(3, auto);

			.box-hashotel-item {
				grid-row: auto !important;
			}

			.box-hashotel-item:nth-child(3n+1) {
				order: unset;
			}

			.box-hashotel-item:nth-child(3n+2) {
				order: unset;
			}

			.box-hashotel-item:nth-child(3n) {
				order: unset;
			}
		}
	}
}

@media (min-width: $sm) and (max-width: $lg) {
	.box-hashotel-column {
		column-count: 2;
	}
}

.hotel-detail {
	.container-text-icon .container-text {
		font-size: $text-size;
	}

	.carousel-inner {
		height: 30vh;
		min-height: 220px;
		display: flex;
		align-items: center;
		justify-content: flex-start;
	}
}

.carousel-indicators {
	display: none !important;
}

a.carousel-control-prev {
	opacity: 0.7;
}

span.carousel-control-next-icon {
	background-color: black;
	border-radius: 50%;
	background-size: 50% 50%;
	font-weight: 900;
	opacity: 0.7;
}

span.carousel-control-prev-icon {
	background-color: black;
	border-radius: 50%;
	background-size: 50% 50%;
	font-weight: 900;
	opacity: 0.7;
}

.row-content{
	gap: 1.5px;
	justify-content: space-between;

	.container-card-room .card-body .container-card-body-header .title {
			overflow: hidden;
			text-overflow: ellipsis;
			 display: -webkit-box;
			-webkit-box-orient: vertical;
			-webkit-line-clamp: 2;
		}
	

	@media (max-width: $md) {
		flex-direction: column;

	}

	@media (min-width: $lg) {

		.container-card-room{
			max-width: 49%;
		}

	}


}