@import "../../../../theme/variable-switch.scss";

.offcanva-pop {
  .offcanvas-header {
    padding-bottom: 0;
  }
  .tittleOffCanva {
    size: $text-size-little;
    font-weight: $font-weight-bold;
  }
  .Offcanva-body {
    display: flex;
    flex-direction: column;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .offcanvas-title {
    margin-top: 1rem;
    size: $text-size-aux;
    font-weight: $font-weight-bold;
  }

  .offcanva-description {
    text-align: justify;
    margin: 0;
  }

  .OffCanva-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .box-reservation {
    vertical-align: bottom;
  }
  .btn-reserva {
    background: $primary;
    color: $textWhite;
    transition: 0.2s;
    font-size: $text-size;
    &:hover {
      color: $textWhite;
    }
  }
  .btn-filter-ico {
    color: $info;
    font-weight: $font-weight-bold;
    font-size: $text-size-small;
  }
  .rate-without-discount {
    font-size: $font-card-without-rate;
    color: $text-colorLight;
    padding: 0;
    margin: 0;
    text-decoration: line-through;
    height: 20px;
  }
  .box-item-price {
    font-weight: 700;
    color: $text-color;
    font-size: $font-card-tittle;
    margin-bottom: 0;
  }
}
