@import "../../../theme/variable-switch.scss";

.con-Vstatic {
	display: flex;
	justify-content: center;
	margin-top: 3rem;
	margin-bottom: 3rem;
}

.div-Vstatic {
	box-shadow: $box-shadow-container;
	border-color: $text-color;
	padding: 3rem;
	background-color: white;
	border-radius: 10px;
	border-color: $text-color;
	color: $text-color;

	h1 {
		font-size: $text-size-aux;
		color: $text-color;
	}

	.title {
		font-size: $text-size-big;
		margin-bottom: 2rem;
		text-align: justify;
		font-weight: 700;
	}

	.subtitle {
		text-align: justify;
		font-size: $text-size-medium;
	}

	.text {
		text-align: justify;
		font-size: $text-size-medium;
		margin-bottom: 50px;
	}

	.btn-voucher {
		font-size: $text-size-low;
	}

	.container-reference {
		display: flex;
		flex-direction: column;
		justify-content: center;
		margin-bottom: 1rem;

		.container-text-duo {
			flex-direction: column;

			.text-left {
				padding-right: 5px;
				font-weight: 700;
			}

			.text-right {
				padding-right: 5px;
				font-weight: 500;
			}
		}
	}


	@media (max-width: $lg) {
		.content {
			padding: 0rem !important;
		}
	}

	@media (max-width: $md) {
		padding: 2rem;

		.title {
			font-size: $text-size-medium;
			font-weight: 600;
		}

		.subtitle {
			margin-top: 2rem;
			font-size: $text-size;
			text-align: justify;
		}

		.text {
			font-size: $text-size;
			margin-bottom: 2rem;
			text-align: justify;
		}
	}

	@media (max-width: $md) {
		padding: 1rem;
	}
}

.container-text-duo {
	width: 100%;
	padding-top: 5px;
	padding-bottom: 5px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: baseline;

	.text-left {
		display: flex;
		color: $text-color;
		font-weight: 700;
	}

	.text-right {
		display: flex;
		font-weight: 500;
	}
}

.con-Vstatic .div-Vstatic .container-reference .container-text-duo .text-left {
	font-size: $text-size-low;
}

.con-Vstatic .div-Vstatic .container-reference .container-text-duo .text-right {
	font-size: $text-size-low;
}