$primary: #ff621d;
$primaryTransparent: #ff611d46 !default;
$primaryLight: #fd921e;
$primaryDark: #b94000;

$secondary: #164375;
$secondaryLight: #355287;
$secondaryDark: #021b49;

$info: #0288d1;
$infoLight: #03a9f4;
$infoDark: #01579b;

$error: #d32f2f;
$errorLight: #ef5350;
$errorDark: #c62828;

$warning: #ed6c02;
$warningLight: #ff9800;
$warningDark: #e65100;

$success: #2e7d32;
$successLight: #4caf50;
$successDark: #1b5e20;

$backgroundBody: #f8f9fa;
$background: #f8f8f8;
$backgroundLight: #f8f8f8;
$background-secondary: #0d0b0b;
$backgroundBox: white;
$backgroundBoxDark: #c5c6c7;
$backgroundBoxLight: #ffffff;


// breakpoints
$xs: 420px;
$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;
$xxl: 1400px;

// FONT
$fontfamily: "Montserrat";

//size
$text-size-super: 45px;
$text-size-mega: 40px;
$text-size-big: 32px;
$text-size-aux: 24px;
$text-size-tall: 22px;
$text-size-medium: 20px;
$text-size-low: 18px;
$text-size: 16px;
$text-size-little: 14px;
$text-size-small: 12px;
$text-size-mini: 10px;
$font-input: $text-size-little;

// color
$text-color: #575756;
$text-colorLight: #9c9090;
$textSecondaryColor: #5a5a5a81;
$textdisableColor: #00000061;
$textWhite: #ffffff;
$textBlack: #000000;

$text-card-color: #000000;
$text-card-color-hover: $primary;

// weight
$font-weight-bold: 700;

// height
$height-sm: 300px;
$border: #565656;

// breadcrumb
$breadcrumb-color-page-list: gray;
$breadcrumb-active-color-page-list: $primaryDark;
// x en caja de busqueda
// $breadcrumb-close-btn: invert(1) grayscale(100%) brightness(200%); // tema obscuro:
$breadcrumb-close-btn: none; // tema claro
//

// caja de busqueda info
$backgroundBoxSecondary: #f0f0f0;
$backgroundBoxSecondaryLight: #f8f8f8;
$backgroundBoxSecondaryDark: #353333;

// shadow
$shadow-list-service-card: 4px -4px 8px 2px rgb(65 82 91 / 16%);
$box-shadow: 0px 0px 5px -2px rgb(0 0 0 / 75%);
$border-radius: 26px;
$border-radius-btn-mobile-search: 8px;
$border-radius-btn: 26px;

// botones
$button-background: $primary;
$button-text-color: $textWhite;
$button-background-hover: $primaryDark;
$button-text-hover: $textWhite;
$color-background-b2b: #1455a1;

// calendario
$calendar: #9e9e9e;
$calendarLight: #cfcfcf;
$calendarDark: #707070;

// SECTIONS

// Booking engine
$box-border-color: $primary;
$box-radius: $border-radius; //0.5rem;
$box-shadow-box: $box-shadow; // 0px 1px 6px 1px rgb(65 82 91 / 16%);
$box-color: $text-color; // booking view edit
$search-box-color: $text-color; // booking without view
$search-label-error: $textWhite;
$search-background-label-error: $error;

// Footer
$footer-background: #eee;
$font-footer-color: $textBlack; // text footer
$font-footer-color-hover: $primaryDark; // text hover footer
$font-footer-color-resalt: $textWhite; // color footer numeros y correo
$font-footer-logo: $text-size-big;
$font-footer-title: $text-size;
$font-footer-text: $text-size-little;
$font-footer-information: $text-size;

// card vertical listados
$font-card-tittle: $text-size-low;
$font-card-text: $text-size-little; //duracion, destino
$font-card-text-small: $text-size-small; //impuestos
$font-card-rate: $text-size;
$font-card-without-rate: $text-size-little;
$font-card-icon: $text-size;
$font-card-content-icon: $text-size-low; //star, mealplan
$font-card-tag: $text-size-little;

// card text
$font-card-tittle-xs: 18px;
$font-card-text-xs: 16px; //duracion, destino
$font-card-text-small-xs: 14px; //impuestos
$font-card-rate-xs: 20px;
$font-card-without-rate-xs: 20px;
$font-card-icon-xs: 16px;
$font-card-tag-xs: 16px;

// ficha detalle princiapl servicios
$font-main-detail-text-rate: $text-size-little;
$font-main-detail-from: $text-size; //from, desde
$font-main-detail-text-small: $text-size-small;
$font-main-detail-rate: $text-size-medium;
$font-main-detail-without-rate: $text-size-little;
$font-main-detail-icon: $text-size;
$font-main-detail-content-icon: $text-size-low; //star, mealplan
$font-main-detail-tag: $text-size-little;
$background-number-imag: #00000080; //card-habs

// listados
$background-page-list: transparent;
$border-page-list: transparent;
$color-page-list: gray;
$color-page-list-active: $primary;
$font-weight-page-list: 700;
$font-weight-page-list-normal: 500;
$border-toggle: #ababab5e;
$icon-toggle: gray;

// estilos para dropdowns
$color-toggle: $secondary;
$background-toggle: $primary;
$color-toggle-hover: rgb(111, 109, 109);

//Modal
$modal-color-btn: $primary;
$modal-color-btn-table: $success;

// headers
$header-color: $text-color;
$ft-header: $text-size-little;
$header-color-hover: $text-color;
$headerBackgound: white;
$textMenu: white;
$textHeaderMenuHover: $secondary;

// dropdown header
$dropDownMenuBackground: white; // fondo dropdown
$headerMobileBackground: white; // fondo dropdown mobile
$activeItemDropdown: $primaryDark; //background item active
$textDropDown: $primary;
$textMenuMobile: white;
$iconMenuBurger: $text-color;

//Booking, cajas de home
//Mobile
$text-color-boxImageBooking-titleMobile: white;
$background-boxImageBooking-inputMobile: white;
$text-color-boxImageBooking-inputMobile: $textMenu;
$borderColorHover-boxImageBooking-inputMobile: $box-border-color;
$borderColor-boxImageBooking-inputMobile: $box-border-color;

$font-card-paxes-reservation-title: 20px;
$font-card-paxes-reservation-title-description: 15px;
$font-card-paxes-reservation-pax-title: 16px;
$font-card-paxes-reservation-pax-description: 14px;
$font-card-paxes-reservation-description: 14px;
$color-background-b2b: #1455a1;

//flight
$bacground-flight: $primary;
$bacground-flight-discount: $primaryDark;
$bacgroundScaleLight: #ebe9e9;
$flightRegular: $primaryDark;
$backgroundRegular: #ebe9e9; // fondos de cajas de vuelos
$flightClub: $primaryDark;
$backgroundClub: #ebe9e9;
$borderRegular: $primary;
$borderClub: $primary;

//family Regular
$familyRegularBasic: $text-color;
$familyRegularClassic: $textSecondaryColor;
$familyRegularPlus: $textSecondaryColor;

//family club
$familyClubBasic: $secondaryDark; // border tab
$familyClubClassic: $secondaryLight;
$familyClubPlus: $primaryDark;

//overlayer
$colorOverLayer: #bfbfbf; // disable options
$colorOverLayerActive: $primary; // disable options
$bannerClub: rgba(207, 207, 207, 0.87);
$shadowclubbtn: #d5d5d5;

// btn flight
$btn-flight: $button-background;
$btn-flight-color: $button-text-color;
$tag: #2e7d32; // tag vuelos
$backgroundBox-toggle: $backgroundBoxSecondary;
$font-button-toggle: $text-size;
$border-radius-toggle: 100px;

// Gallery
$chipBackground: $backgroundLight;
$chipBackgroundColor: $text-color;
$chipBackgroundActive: $primary;
$chipBackgroundActiveColor: $textWhite;
$chipBorderColor: $primaryLight;
$chipBorderSizePx: 1px;
$galleryBackground: $background-secondary;
$galleryfontColor: $text-color;
$galleryIconActive: $primaryLight;
$galleryFontSize: $text-size-low;

//box shadow 
$card-img-padding: 10px;
$box-shadow-card: 0px 0px 6px -1px #c7c8c990;
$box-shadow-container: 0px 0px 6px -1px #c7c8c990;

$card-border-bottom-left-radius: 0px;

$borderBottomHeader: #ffffff00;


:export {
   modalColor: $modal-color-btn;
   modalSucess:  $modal-color-btn-table;
}

// Btn styles
$btn-background: $primary;
$btn-color: $backgroundBoxLight;
$btn-active: $primaryDark;