@import "../../../../theme/variable-switch.scss";

.skeleton-card {
  width: 19rem;
  .skeleton-card-img {
    width: 100%;
    height: 150px;
    border-radius: $border-radius;
  }
}

.card-container-home {
  width: 19rem;
  padding: 0;
  padding-bottom: 69px;
}



