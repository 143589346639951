@import "../../../theme/variable-switch.scss";
@import "../../../components/galleries/Collage/components/collage.scss";
@import "../../../components/bookings/BookingInformation/Components/bookingInformationStyle.scss";
@import "../../../components/boxes/BoxDetailSeviceTitleRate/components/style.scss";
@import "../../../components/boxes/BoxServiceRateTour/components/style.scss";

.box-detail-service-title-rate {
    .box-title {
        padding-right: 15px;
        .container-stars{
            .box-star{
                color: var(--primary);
            }
        }
    }
}

.box-hashotel-column {
    column-count: 3;
    
    .box-hashotel-item {
        text-align: left;
        margin-bottom: 15px;
        .container-text-icon {
            display: block;
        }
    }
}

.box-tittle-column-state{
    height: $height-sm;
    overflow: hidden;
    transition-duration: 3s, 2s;
    transition-delay: 2s, 3s;
}

.btn-more-details{
    text-decoration: none;
    color: $primary;
    font-size: $text-size-little;
    &:active {
        background-color: transparent;
    }
}
.container-about-hotel{
    transition: 1s;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
    &.box-info-show {
        -webkit-line-clamp: initial;
    }
}
.button-see-more{
    margin-top:20px;
    margin-left: auto;
    margin-right: auto;
    display: block;
    &:hover{
        color: $textWhite;
    }
}



@media (max-width: $sm) {
    .box-hashotel-column {
        column-count: 1;
    }

    .collage {
        .container-duo {
          display:none;
      }
    }
}

@media (min-width: $sm) and (max-width: $lg) {
    .box-hashotel-column {
        column-count: 2;
    }
}