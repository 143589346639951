.form-select-count-pax {
    display: flex;
    align-items: center;

    button {
        border-radius: 1.5rem;
        width: 21px;
        height: 21px;
        padding: 0;
        display: flex;
        align-items: flex-end;
        justify-content: center;
    }
    .form-select-count-pax-item-disabled {
        border-color: var(--bs-gray-600);
        color: var(--bs-gray-600);
    }
    .form-select-count-pax-item {}
}