@import "../../../theme/variable-switch.scss";
@import "../../../components/galleries/Collage/components/collage.scss";
@import "../../../components/bookings/BookingInformation/Components/bookingInformationStyle.scss";
@import "../../../components/boxes/BoxDetailSeviceTitleRate/components/style.scss";

.box-detail-service-title-rate {
  .box-title {
    padding-right: 15px;

    .container-stars {
      .box-star {
        color: var(--primary);
      }
    }
  }
}

.box-hashotel-column {
  column-count: 3;

  .box-hashotel-item {
    text-align: left;
    margin-bottom: 15px;

    .container-text-icon {
      display: block;
    }
  }
}

.box-tittle-column-state {
  height: $height-sm;
  overflow: hidden;
  transition-duration: 3s, 2s;
  transition-delay: 2s, 3s;
}

.details-title-description {
  font-size: $text-size-aux;
  margin-bottom: 24px;
}

.container-information-service-bg {
  border-radius: $border-radius;
}

.container-about-hotel {
  transition: 1s;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;

  &.box-info-show {
    -webkit-line-clamp: initial;
  }
}

.button-see-more {
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
  display: block;

  &:hover {
    color: white;
  }
}

.tua-flight {
  padding: 20px;
  background: white;
  box-shadow: 0 0.13rem 0.5rem 0 rgb(0 0 0 / 6%),
    0 0.06rem 0.13rem 0 rgb(0 0 0 / 8%);
  border-radius: 12px;

  .tooltip-club {
    color: $primary;
  }
}

.flightListsHead {
  .head-tab-flight {
    div {
      border-radius: 6px 6px 0 0;
      height: 47px;
      text-align: center;
      float: left;
      width: 100%;
    }

    .regular-flight {
      background: $flightRegular;
      margin-right: 1px;
    }

    .club-flight {
      background: $flightClub;

      .tooltip-club {
        font-size: $text-size-medium;
        cursor: pointer;
      }
    }

    .label-heading {
      color: white;
      margin-bottom: 0;
      line-height: 19px;
      padding: 14px 0;
      font-size: 16px;
    }
  }
}

.top-info-flight {
  h3,
  p {
    color: $text-color;
  }
}

#fixed-head {
  position: sticky;
  top: 98px;
  z-index: 99;
}

@media (max-width: $md) {
  .btn-reservation {
    color: $textWhite;
    background: $primary;
    border: none;
  }
  .btn-reservation:active {
    color: $textWhite !important;
    background: $primaryDark;
    border: none;   
  }
  .btn-reservation:hover {
    color: $textWhite !important;
    background: $primaryDark;
    border: none;   
  }
}

@media (max-width: $sm) {
  .box-hashotel-column {
    column-count: 1;
  }

  .collage {
    .container-duo {
      display: none;
    }
  }

  .details-title-description {
    font-size: $text-size-medium;
  }

  .container-information-service-bg {
    border-radius: $border-radius;

    h5 {
      font-size: $text-size-low;
    }
  }
}

@media (max-width: $lg) {
  //flight
  .flightListsHead .head-tab-flight .label-heading {
    font-size: $text-size-small;
  }
}

@media (min-width: $sm) and (max-width: $lg) {
  .box-hashotel-column {
    column-count: 2;
  }
}
