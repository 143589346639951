@import "../../theme/variable-switch.scss";

.my-reservations {
  button.p-button.p-component.btn-table {
    background: $primary;
    border: none;
  }
  button.p-button.p-component.p-splitbutton-defaultbutton {
    background: $primary;
    border: none;
  }

  .p-inputtext:enabled:focus {
    box-shadow: none;
    border-color: none;
  }

  button.p-button.p-component.btn-table {
    &:focus {
      border: none;
    }
  }
  .p-button:focus {
    box-shadow: none;
  }
  button.p-button.p-component.p-splitbutton-menubutton.p-button-icon-only {
    background: $primary;
    border: none;
  }
  span.p-dropdown-trigger-icon.p-clickable.pi.pi-chevron-down {
    color: $primary;
  }
  .p-dropdown:not(.p-disabled):hover {
    border: solid 1px #adacac;
  }
  .p-dropdown:not(.p-disabled).p-focus {
    border: solid 1px #adacac;
    box-shadow: none;
  }
}

.p-column-filter-buttonbar .p-button:not(.p-button-icon-only) {
  background: $primary;
  border: none;
  color: $textWhite;
  &:focus {
    border: none;
    box-shadow: none;
  }
}

li.p-dropdown-item.p-highlight {
  background: $primary !important;
  color: $textWhite !important;
}

.p-menu .p-menuitem-link:not(.p-disabled):hover {
  background: $primary !important;
  color: $textWhite !important;
  .p-menu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
    color: $textWhite !important;
  }
  .p-menuitem-text {
    color: $textWhite !important;
  }
}

.p-menu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
  color: $textWhite !important;
}

.p-dropdown:not(.p-disabled).p-focus {
  box-shadow: none;
  border: solid 1px #adacac;
}
.p-dropdown:not(.p-disabled):hover {
  border-color: #adacac;
}

.p-button.p-button-sm {
  background: $primary !important;
  color: $textWhite !important;
}
.popover-calendar-button .popover-body .rdrMonth .rdrDays .rdrDay .rdrEndEdge {
  background: $primary !important;
  color: $textWhite !important;
}

.popover-calendar-button
  .popover-body
  .rdrMonth
  .rdrDays
  .rdrDay
  .rdrStartEdge {
  background: $primary !important;
  color: $textWhite !important;
}
