@import "../../../theme/variable-switch.scss";

.not-found-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;;
    height: 100vh;

  }

  .not-found-container-image {
    background-image: url(../../../assets/img/404.png);
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center center;
    background-size: cover;
  }
  
  .not-found-title {
    font-size: $text-size-big;
    font-weight: bold;
  }

.not-found-text{
  font-size: $text-size-low;
  font-weight: bold;
  text-align: center;
}
  .not-found-404 {
    margin-top: 16px;
    font-size: 80px;
    color: $primary;
  }
  
  .go-home-button {
    margin-top: 32px;
    padding: 12px 24px;
    font-size: $text-size-low;
    font-weight: bold;
    border-radius: 20px;
    background-color: $primary;
    color: $textWhite;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
    text-decoration:none;
  }
  
  .go-home-button:hover {
    background-color: $primaryDark;
  }