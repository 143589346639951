@import "../../../../theme/variable-switch.scss";

.flight-selected {
  .container-selected-flight {
    width: 100%;
    background: $backgroundBoxSecondaryLight;
    margin-bottom: 20px;
    box-shadow: 0 2px 4px 0 rgb(0 0 0 / 6%), 0 1px 1px 0 rgb(0 0 0 / 8%);
    border-radius: 12px;
    .inner-container {
      display: table-cell;
      width: 50%;

      .inner-content {
        /* background-color: #fff; */
        display: block;
        height: -webkit-max-content;
        height: -moz-max-content;
        height: max-content;
        padding: 15px 30px 15px 15px;
        h5 {
          margin-top: 3px;
          margin-bottom: $text-size-low;
          font-size: 20px;
          span {
            color: $tag;
          }
        }
        .flightroute {
          margin-bottom: 20px;
          margin-top: 6px;
        }
        .flighttime {
          font-size: $text-size-low;
          font-weight: 700;
          color: $background-secondary;
        }
        .flightdetails {
          line-height: 24px;
          text-decoration: none;
          display: inline-block;
          color: $secondary;
          font-size: $text-size-little;
          cursor: pointer;
        }
        .faretype {
          color: $flightRegular;
        }
        /*.club.basic{
                    color: $flightClub;
                } */
        .regular.basic {
          color: $familyRegularBasic;
        }
        .club.basic {
          color: $familyClubBasic;
        }
        .regular.classic {
          color: $familyRegularClassic;
        }
        .club.classic {
          color: $familyClubClassic;
        }
        .regular.plus {
          color: $familyRegularPlus;
        }
        .club.plus {
          color: $familyClubPlus;
        }
        .changeflight {
          line-height: 24px;
          text-decoration: none;
          display: inline-block;
          color: $primary;
          font-size: $text-size-little;
          text-align: right;
          width: 100%;
          cursor: pointer;
        }
        .flightamenities {
          position: relative;
          height: 100%;
          .priceflight {
            position: absolute;
            bottom: 3px;
            right: 15px;
            text-align: right;
            font-size: $text-size-low;
            font-weight: 500;
            span {
              font-size: $text-size-mini;
              display: block;
            }
            strong {
              font-size: $text-size-low;
              display: block;
            }
          }
        }
      }
    }
    .flight-info {
      padding-left: 10px;
      position: relative;
      border-right: 1px dashed $backgroundBoxDark;
      .farecolor {
        background-color: $flightRegular;
        padding-left: 10px;
        border-radius: 12px 0 0 12px;
        position: absolute;
        left: 0;
        width: 10px;
        top: 0;
        height: 100%;
      }
      .farecolor.regular.basic {
        background-color: $familyRegularBasic;
      }
      .farecolor.club.basic {
        background-color: $familyClubBasic;
      }
      .farecolor.regular.classic {
        background-color: $familyRegularClassic;
      }
      .farecolor.club.classic {
        background-color: $familyClubClassic;
      }
      .farecolor.regular.plus {
        background-color: $familyRegularPlus;
      }
      .farecolor.club.plus {
        background-color: $familyClubPlus;
      }
    }
    .flight-feature {
      position: relative;
      .circle-corner-top {
        width: 24px;
        height: 24px;
        background: white;
        display: block;
        position: absolute;
        border-radius: 50%;
        top: -12px;
        left: -12px;
        z-index: 9;
      }
      .circle-corner-bottom {
        width: 24px;
        height: 24px;
        background: white;
        display: block;
        position: absolute;
        border-radius: 50%;
        bottom: -12px;
        left: -12px;
        z-index: 9;
      }
    }
    .list-amenities {
      ul {
        list-style: none;
        margin: 0;
        padding: 0;
        li {
          font-size: $text-size-small;
          margin-bottom: 16px;
          .textpar {
            margin-bottom: 6px;
            display: inline-grid;
            .personalItemLabel {
              line-height: 15px;
              color: $background-secondary;
            }
          }
          .personalItemSubLabel {
            font-size: $text-size-mini;
            line-height: 12px;
            color: rgba(0, 0, 0, 0.6);
            margin-top: 2px;
            strong {
              font-size: $text-size-small;
            }
          }
          .disabled {
            color: rgba(0, 0, 0, 0.25);
            .icon-item {
              font-size: $text-size-small;
            }
            .personalItemSubLabel {
              color: rgba(0, 0, 0, 0.25) !important;
              font-size: $text-size-small;
            }
          }
        }
      }
    }
  }
}
.tooltip-text {
  display: flex;
  span {
    font-size: $text-size-mini;
  }
}

@media (max-width: $md) {
  .flight-selected .container-selected-flight .inner-container {
    display: block;
    width: 100%;
  }
  .flight-selected .container-selected-flight .flight-info {
    border-right: none;
    border-bottom: 1px dashed $backgroundBoxDark;
  }
  .flight-selected
    .container-selected-flight
    .inner-container
    .inner-content
    .flightamenities
    .priceflight {
    position: relative;
    right: 0;
  }
  .flight-selected
    .container-selected-flight
    .flight-feature
    .circle-corner-bottom {
    top: -12px;
    right: -12px;
    left: inherit;
    bottom: inherit;
  }
}
