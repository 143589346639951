@import "../../../../theme/variable-switch.scss";

.box-title {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .title {
    font-size: $text-size-tall;
    font-weight: 700;
    color: $textBlack;
    margin-bottom: 5px;
  }

  .box-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 28px;
  }

  .divider-horizontal {
    color: var(--bs-gray-500);
    margin-bottom: 16px;
    margin-top: 0;
  }

  .tipography {
    font-size: $text-size;
    color: var(--text-color-secondary);
  }

  .container-stars {
    flex-grow: 1;
    display: flex;
  }

  .box-star {
    color: var(--primary);
    font-size: 1.5rem;
  }

  .container-location {
    display: flex;
    align-items: center;
    padding-bottom: 10px;
  }

  .container-item-location {
    color: var(--text-color-secondary);
    display: inline-flex;
    align-items: baseline;
  }
}

@media (max-width: $sm) {
  .box-title {
    .title {
      font-size: $text-size-aux;
    }

    .tipography {
      font-size: $text-size;
    }
  }
}
