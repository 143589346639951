@import "../../../theme/variable-switch.scss";

.grid-gallery-section {
  margin-bottom: 3rem;
  .card-overlay-middle {
    width: 100% !important;
    background: rgba(0, 0, 0, 0.4);
	  box-shadow: $box-shadow-card;
    .text p {
      height: 20px !important;
    }
  }
  .title {
    font-size: 28px;
    text-align: center;
    margin-top: 3rem;
    margin-bottom: 3rem;
  }

  .card-overlay-middle .text .title {
    text-align: inherit;
  }

  a {
    width: 300px;
    height: 151px;
    margin-bottom: 0.51rem;
    display: flex;
    justify-content: center;
    .card-overlay {
      min-width: 95% !important;
    }
  }

  .card-overlay {
    max-width: 90%;
    height: 151px;
    margin-bottom: 0.51rem;
  }

  .rowContaint {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    gap: 1rem;
  }
  @media (max-width: $md) {
    margin-bottom: 2rem;
    .container-title {
      .title {
        display: none;
      }
    }

    .card-overlay {
      max-width: 100%;
    }
    a {
      width: 100%;
    }
    .card-overlay-middle .text p {
      height: 30px;
    }
  }

  @media (min-width: $lg) and (max-width: $xxl) {

    a {
      width: 430px;
      height: 200px;
      .card-overlay {
        width: 430px;
        height: 200px;
      }
    }
  }
  @media (min-width: $xxl) {
    .rowContaint {
      display: flex;
      justify-content: space-around;
    }
    a {
      height: 212px !important;
      width: 300px !important;
    }
    .card-overlay {
      height: 212px;
      width: 300px;
    }

    .biggerImage {
      margin-bottom: 1rem;
      a {
        height: 341px !important;
        width: 300px !important;
      }
      .card-overlay {
        height: 341px;
        width: 300px;
      }
    }

    .bottomImg {
      margin-top: -8rem;
    }
  }
}
