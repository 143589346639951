@import "../../../theme/variable-switch.scss";

.card-container-image {
	position: relative;
	.card-content-img{
		max-height: 100%;
	}
	.container-label {
		position: absolute;
		background: $primary;
		color: $textWhite;
		font-size: $font-card-tag;
		padding: 7px;
		z-index: 1;
		.icon,
		.text {
			color: $textWhite;
		}

		svg {
			font-size: $font-card-icon;
		}
	}
	
	&.left .container-label{
		border-bottom-right-radius: $border-radius;
	}
	&.right .container-label{
		border-bottom-left-radius: $border-radius;
		right: 0;
	}
}