@import "../../../../theme/variable-switch.scss";

//tour
.box-rate-price {
  min-width: 20%;
  display: flex;
  justify-content: end;
}
.box-service-rate-tour {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-top: auto;
  margin-bottom: auto;
  min-width: 243px;

  // tours
  .rate-tour-card {
    font-size: $text-size-medium;
  }
  .rate-tour {
    font-size: $font-main-detail-rate;
    white-space: nowrap;
    line-height: 1em;
    font-weight: 500;
    display: inline-block;
    margin: 10px 0px;
    text-transform: uppercase;
    span {
      font-size: $text-size-little;
    }
  }
  .informations {
    font-size: $text-size-little;
  }
  button {
    margin-top: 10px;
    transition: 0.2s;
    border-radius: $border-radius-btn;
    font-size: $text-size-little;
    color: $textWhite;

    p {
      font-size: 12px;
    }
    &:hover {
      color: $textWhite;
    }
  }
  .divider-horizontal {
    color: var(--bs-gray-500);
    margin-bottom: 16px;
    margin-top: 0;
    width: 100%;
    margin-top: 20px;
  }

  .rate-per {
    font-size: 14px;
  }
  .text-rate-tour {
    font-size: $font-main-detail-from;
  }
  &.bg-rate {
    border-radius: $border-radius;
    background-color: var(--bs-gray-100);
    padding: 1.5rem !important;
    //max-width: 250px;
  }
}

// @media (max-width: $lg) {
//     .box-service-rate {
//         .rate {
//             font-size: 33px;
//         }
//     }
// }

@media (max-width: $md) {
  .box-service-rate-tour {
    margin-top: 20px;

    //main detail
    .rate-tour {
      font-size: $text-size-low;
      span {
        font-size: $text-size-small;
      }
    }
    .informations {
      font-size: $text-size-small;
    }
    .text-rate-tour {
      font-size: $text-size-little;
    }

    //activities
    .rate-tour-card {
      font-size: $text-size-medium;
    }
    //activities
    .rate-per {
      font-size: 14px;
    }
  }
}
