@import "../../../../theme/variable-switch.scss";

.container-text-icon {
	display: flex;
	justify-content: center;

	.container-icon {
		display: inline-flex;
		padding-right: 5px;
	}

	.container-text {
		display: inline-flex;
	}

	.color-primary {
		color: $primary;
	}
}

@media (max-width: $sm) {
	.container-text-icon {
		justify-content: start;
	}
}