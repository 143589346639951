@import "../../../../theme/variable-switch.scss";

.card-sv-large {
	overflow: hidden;
  border-radius: $border-radius !important;
  box-shadow: $box-shadow-card;
	&.card-border{
		border: 2px solid $success;
	}
  .content-box {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .carousel {
    width: 100%;

    .carousel-inner {
      height: 100%;
    }

    .carousel-item {
      height: 100%;
    }

    .carousel-indicators {
      display: none;
    }

    .carousel-control-prev,
    .carousel-control-next {
      opacity: 0.7;

      &:hover {
        opacity: 0.8;
      }
    }

    .carousel-control-prev-icon,
    .carousel-control-next-icon {
      background-color: black;
      border-radius: 50%;
      background-size: 50% 50%;
      font-weight: 900;
      opacity: 0.7;

      &:hover {
        opacity: 0.9;
      }
    }
  }

  .card-content-img {
    position: relative;
    max-width: 287px;

    .card-label {
      position: absolute;
      z-index: 2;
      right: 0;
      white-space: nowrap;
      font-weight: 700;
      border-bottom-left-radius: $border-radius;
      border-top-right-radius: 0;
      background: $primary;
      color: $textWhite;
      font-size: $font-card-tag;
      padding: 7px;

      svg {
        font-size: $font-card-icon;
      }
    }

    .card-img {
      width: 287px;
      height: 239px;
      border-radius: 0;
      object-fit: cover;
      border-top-left-radius: $border-radius;
      border-bottom-left-radius: $border-radius;

      &.placeholder-img {
        background-color: var(--bs-gray-400);
      }
    }
  }

  .card-sv-content-info {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    justify-content: space-between;
    padding-top: 15px;
    padding-bottom: 15px;

    .rate-discount {
      color: $success;
      display: flex;
      align-items: center;
      font-size: $text-size;
      margin-top: 10px;
    }

    .rate {
      font-size: $text-size-medium;
      font-weight: $font-weight-bold;
      color: $text-card-color;
    }
    .rate:hover{
      color: $text-card-color-hover !important;
    }

    .text-color {
      color: $text-color;
    }

    .text-nowrap {
      white-space: nowrap;
    }

    .card-sv-content-info-start {
      padding: 0 20px;
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .icon-promotion {
        position: initial;
        margin-right: 10px;
        font-size: $text-size;
        color: var(--primary);
      }

      .card-content-description {
        margin-top: 2px;
        padding-top: 2px;
        border-top: 1px solid var(--bs-gray-200);
        font-size: $text-size-small;
        overflow: hidden;
      }
    }

    .card-sv-content-info-end {
      border-left: 1px solid var(--bs-gray-200);
      padding: 0 20px;
      min-width: 210px;
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      align-items: flex-end;
      justify-content: center;

      .card-sv-content-info-net-rate {
        font-size: $text-size-little;
      }

      .overlay-container {
        font-size: $text-size-medium;
        .button-overlay {
          border: 0;
          border-radius: 0;
          padding: 0;
          height: initial;
          width: initial;
        }
      }

      button,
      .card-btn-reservation {
        margin-top: 10px;
        padding: 5px 15px;
        background-color: $button-background !important;
        color: $button-text-color !important;
        border-radius: $border-radius-btn !important;
        display: flex;
        flex-direction: column;
        align-items: center;

        span {
          font-size: $font-card-tittle;
        }

        small {
          font-size: $text-size-mini;
        }

        &:hover {
          background-color: $button-background-hover !important;
          color: $button-text-hover !important;

          small {
            background-color: $button-background-hover !important;
            color: $button-text-hover !important;
          }
        }
      }

      .old-fee {
        text-decoration: line-through;
      }
    }

    .box-title {
      a {
        text-decoration: none;
        color: $text-card-color;
      }
      a:hover {
      color: $text-card-color-hover !important;
      }

      .container-stars {
        flex-grow: initial;
      }
    }
  }

  .accordion {
    .accordion-item {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      border-top: 1px solid var(--bs-gray-400);

      .accordion-header {
        button {
          background-color: var(--bs-accordion-active-bg);
        }
      }

      .accordion-body {
        .card-large-table-container {
          overflow-y: auto;
          max-height: 409px;
        }
      }
    }
  }

  .table {
    thead {
      tr {
        th {
          white-space: nowrap;
        }
      }
    }

    tbody {
      tr {
        td {
          button {
            background-color: white;

            &:hover {
              background-color: $primary;
            }
          }

          .t-d-name {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 5px;
          }
        }
      }
    }
  }
}

@media (max-width: $lg) {
  .card-sv-large {
    max-width: 373px;

    .content-box {
      flex-direction: column;
    }

    .card-content-img {
      .card-label {
        border-top-right-radius: $border-radius;
      }

      .card-img {
        width: 100%;
        max-width: 373px;
        border-radius: 0;
        object-fit: cover;
        border-top-left-radius: $border-radius;
        border-top-right-radius: $border-radius;
      }
    }

    .card-sv-content-info {
      flex-direction: column;
      flex-grow: initial;

      .card-sv-content-info-start {
        padding: 0 20px;
        flex-grow: initial;
      }

      .card-sv-content-info-end {
        flex-grow: initial;
        border-left: none;
        align-items: flex-end;

        .card-sv-content-info-net-rate {
          font-size: $text-size-medium;
        }
      }

      .box-title {
        .box-title-content {
          line-height: 2;
          -webkit-line-clamp: 2;
        }
      }
    }
  }
}

@media (min-width: $sm) and (max-width: $lg) {
  .card-sv-large {
    width: 373px;

    .card-content-img {
      max-width: 100%;
    }
  }
}

@media (max-width: $sm) {
  .card-sv-large {
    border-radius: $border-radius !important;

    .card-content-img {
      max-width: 100%;
    }
  }
}
