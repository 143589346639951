@import "../../../../theme/variable-switch.scss";

.box-whitout-result {
  width: 100%;
  height: 200px;
  border: 1px solid var(--bs-gray-200);
  border-radius: 8px;
  box-shadow: 0px 0px 5px -2px rgb(0 0 0 / 75%);
  background-color: var(--bs-gray-100);
  text-align: center;
  justify-content: center;
  margin-bottom: 3rem;

  .container-icon-whitout-reesults {
    width: 50px;
    font-size: 32px;
    color: $primary;
    margin-left: auto;
    margin-right: auto;
  }

  p {
    font-size: $text-size-aux;
    padding-left: 30px;
    padding-right: 30px;
   @media (max-width:$md) {
     font-size: $text-size-low;
  }
  }

}
