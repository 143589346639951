.affiliation-container {
  display: flex;
  .col-form {
    background: #1455a114;
    display: grid;
    place-items: center;
    .container-banner-video {
      height: 600px;
    }
  }
}
