@import "../../../../theme/variable-switch.scss";

.card-container {
  display: flex;
  justify-content: center;
  padding: 10px 5px 10px 5px;
  box-sizing: border-box;

  a {
    text-decoration: none;
    color: initial;
  }

  .url-card-services {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
  }

	.card{
		overflow: hidden;
		&.card-border{
			border: 2px solid $success;
		}
	}

  .card-img {
    width: 100%;
    height: 13rem;
    border-radius: $border-radius;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
    object-fit: cover;
  }

  .carousel-indicators {
    display: none;
  }

  .carousel-control-prev,
  .carousel-control-next {
    opacity: 0.7;

    &:hover {
      opacity: 0.8;
    }
  }

  .carousel-control-prev-icon,
  .carousel-control-next-icon {
    background-color: black;
    border-radius: 50%;
    background-size: 50% 50%;
    font-weight: 900;
    opacity: 0.7;

    &:hover {
      opacity: 0.9;
    }
  }

  .card-div {
    width: 100%;
    font-weight: 700;
    position: absolute;
    padding: 4px;
    z-index: 1;
  }

  .card-label {
    border-top-right-radius: $border-radius;
    border-bottom-left-radius: $border-radius;
    float: right;
    background: $primary;
    color: $textWhite;
    font-size: $font-card-tag;
    padding: 7px;
    margin-top: -4px;
    margin-right: -4px;

    svg {
      font-size: $font-card-icon;
    }
  }

  .card-body {
    border-top: 2px solid $border-toggle;
  }

  .card-content {
    align-items: inherit;
    display: flex;
    flex-direction: column;
		justify-content: space-between;
    height: 100%;
    min-height: 135px;

		.container-not-avalability{
			align-self: end;
		}
  }

  .card-content-button {
    background: $primary;
    align-items: center;
    color: $textWhite;
  }

  .box-item-text {
    font-size: $font-card-text-small;
    color: $text-colorLight;
    padding: 0;
    margin: 0;
		&.bold{
			font-weight: bold;
			color: $text-card-color;
		}
  }

  .rate-without-discount {
    font-size: $font-card-without-rate;
    color: $text-colorLight;
    padding: 0;
    margin: 0;
  }

  .box-item-price {
    font-weight: 700;
    color: $text-card-color !important;
    font-size: $font-card-tittle;
    margin-bottom: 0;
  }

  .container-flex {
    flex-direction: initial;
    justify-content: space-between;
  }

  .container-duration {
    display: flex;
    font-size: $font-card-text;
  }

  .icono {
    color: var(--primary) !important;
    padding-right: 5px;
  }

  &:hover {
    .box-title-content-title {
      color: $text-card-color-hover !important;
    }

    .box-item-price {
      color: $text-card-color-hover !important;
    }

    .card {
      box-shadow: 2px -20px 8px 2px rgba(65, 82, 91, 0.16);
      transition: box-shadow 0.3s ease-in-out,
        -webkit-box-shadow 0.3s ease-in-out;
    }

    .container-discount {
      color: $success !important;
    }

    .container-flex-text-icon {
      color: var(--primary) !important;
    }
  }

  .btn-filter {
    font-size: $text-size-little;
    color: $text-color;
    padding-left: 0;
    text-align: initial;
    bottom: 15px;
    left: 15px;
    position: absolute;
  }

  .btn-filter:hover {
    background: none;
    border: none;
    color: $text-color;
  }
}

section.top-things-to-do-section.hotel {
  .card-container {
    display: contents;
  }
	.slick-arrow{
		width: auto;
		height: auto;
		z-index: 1;
		&.slick-prev{
			left: calc(0px - $text-size-big);
		}
		&.slick-next{
			right: calc(0px - $text-size-big);
		}
	}
	.slick-arrow::before{
		font-size: $text-size-big;
		color: $primary;
	}

	@media (max-width:$xl) {
		.slick-arrow{
			width: $text-size-mega;
			height: $text-size-mega;
			transform: none;
			position: absolute;
			top: auto;
			bottom: calc(0px - $text-size-mega);
			&.slick-prev{
				left: calc(50% - $text-size-mega);
			}
			&.slick-next{
				right: calc(50% - $text-size-mega);
			}
		}
	}

	@media (max-width:$md) {
		.slick-arrow{
			display: none !important;
		}
	}
}

.card-content-img {
  padding: $card-img-padding;
}

.top-destination-container .img {
  padding: $card-img-padding;
  border-radius: $border-radius;
  border-bottom-left-radius: $card-border-bottom-left-radius;
  border-bottom-right-radius: $card-border-bottom-left-radius;
}

.top-destination-container .img-wrapper {
  border-bottom-left-radius: $card-border-bottom-left-radius;
  border-bottom-right-radius: $card-border-bottom-left-radius;
}

.top-destination-container .top-destination-align-center {
  margin-top: 0.8rem;
}