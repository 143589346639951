@import "../../theme/variable-switch.scss";

.login-bg {
  background-color: var(--background-booking);
  height: 100%;
  .login-container {
    margin: auto;
    display: flex;
    height: 100%;
    justify-content: center;
    flex-direction: column;

    .login-card {
      min-width: 420px;
      margin-left: auto;
      margin-right: auto;
      margin-top: 35px;
      margin-bottom: 35px;
      border-radius: $border-radius;

      .login-box {
        display: flex;
        flex-direction: column;
        padding-left: 15px;
        padding-right: 15px;
      }

      .text-warning {
        color: var(--bs-red);
      }
      #btn-login-submit {
        border-radius: $border-radius-btn;
        background-color: $button-background !important;
        color: $button-text-color !important;
        &:hover {
          background-color: $button-background-hover !important;
          color: $button-text-hover !important;
        }
      }
    }
  }
}

@media (max-width: $md) {
  .login-bg {
    .login-container {
      .login-card {
        min-width: initial;
        margin-left: 15px;
        margin-right: 15px;
      }
    }
  }
}
