@import "../../../../theme/variable-switch.scss";

.container-label {
	display: flex;
	gap: 3px;
	.text {
		color: $text-color;
		white-space: nowrap;
	}
	.icon {
		color: $info;
	}
}