@import "../../../theme/variable-switch.scss";

.dropdown-list {
	button.dropdown-toggle.btn.btn-primary {
		background: none !important;
		color: $text-color !important;
		padding-left: 5px;
		padding-right: 5px;
		border: none; 
	}
}

.list-filters {
	.dropdown-menu.show {
		left: auto !important;
		overflow: hidden;
		right: 5px !important;
	}
}