@import "../../../../theme/variable-switch.scss";

.box-form {
	display: flex;
	flex-direction: column;
	width: 100%;
	padding: 30px;
	background-color: white;
	border-radius: 10px;
	border-color: $text-color;
	box-shadow: $box-shadow-container;
}

.box-form-container {
	display: flex;
	flex-direction: row;
	justify-content: space-between;

}

.box-summary {
	padding: 1.5rem 1.5rem 2rem 1.5rem;
	display: flex;
	flex-direction: column;
	width: 100%;
	background-color: white;
	border-radius: 10px;
	box-shadow: $box-shadow-container;
    border-color: white;
}

.box-summary-div {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.box-summary-div-hotel {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

@media (max-width: $xl) {
	.box-summary-div-hotel {
		display: flex;
		justify-content: space-between;
		flex-direction: column;
	}

}

.box-summary-item {
	display: flex;
	flex-direction: column;
}

.box-summary-item-icon {
	display: flex;
	align-items: center;
	font-size: 1.4rem;
	justify-content: center;
}

.border-summary-item {
	border-bottom: 1px solid $backgroundBoxSecondary;
	margin-bottom: 3px;
}

.box-form-item {
	width: 49%;
}

.box-form-item-full {
	width: 100%;
}

.flightSelected {
	margin: 8px 10% 12px;
	text-align: center;
	position: relative;

	.airport {
		font-size: $text-size-aux;
		line-height: 29px;
		font-weight: 700;
	}

	.time {
		font-size: $text-size-small;
		line-height: 15px;
		color: rgba(0, 0, 0, .6);
	}

	.separator {
		background: #080206;
		position: absolute;
		width: 20px;
		height: 2px;
		top: 35%;
		left: 50%;
		margin-left: -10px;
	}
}

.date {
	font-size: $text-size-small;
	line-height: 15px;
}

.img-sumary {
	border: none;
	border-radius: 4px;
	max-width: 100%;
	border-bottom: 3px $primary;
	max-height: 240px;
	margin-bottom: 3%;
	object-fit: cover;
}



@media (max-width: $sm) {
	.box-form {
		padding-left: 15px;
		padding-right: 15px;
	}

	.box-form-container {
		flex-direction: column;

		.box-form-item {
			width: 100%;
			margin-bottom: 15px;
		}
	}

	.box-summary {
		padding-left: 15px;
		padding-right: 15px;
	}

	.box-summary-div-hotel {
		display: flex;
		justify-content: space-between;
		flex-direction: column;
	}
}

.your-reservation {
	border: none;
	box-shadow: none !important;
	background: none !important;

	.title-vuelo {
		border-radius: 0 !important;
	}
}