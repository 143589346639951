@import "../../../../theme/variable-switch.scss";

.box-club-flight{
    width: 100%;
    float: left;
    background: #fff;
    position: relative;
    .closeDialog{
        position: absolute;
        top: 10px;
        right: 10px;
        cursor: pointer;
        z-index: 99;
        float: right;
        width: 24px;
        height: 24px;
        text-align: center;
    }
    .best-price{
        background-image: url(https://www.volaris.com/assets/images/vclub-side-banner.jpg);
        border-radius: 12px 0 0 12px;
        background-size: cover;
        background-position: center;
        min-width: 270px;
    }
    .vclub-banner{
        position: relative;
        background: $bannerClub;
        height: 100%;
        -webkit-border-top-left-radius: 12px;
        -webkit-border-top-right-radius: 0;
        -webkit-border-bottom-right-radius: 0;
        -webkit-border-bottom-left-radius: 12px;
        -moz-border-radius-topleft: 12px;
        -moz-border-radius-topright: 0;
        -moz-border-radius-bottomright: 0;
        -moz-border-radius-bottomleft: 12px;
        border-radius: 12px 0 0 12px;
        .vclub-banner-content{
            padding: 0 30px;
            position: absolute;
            top:50%;
            left: 50%;
            transform: translate(-50%,-50%);
            width: 100%;
            p{
                margin-bottom: 0;
                font-size: $text-size-big;
                font-weight: 700;
                line-height: initial;
                color: #fff;
                max-width: 190px;
                margin: 0 auto;
            }
            span{
                text-align: center;
                font-size: $text-size-low;
                font-weight: 700;
                line-height: initial;
                color: #fff;
                max-width: 190px;
                margin: 0 auto;
            }
        }
    }
    .vclub-container{
        padding: 40px;
        .vclub-group-2{
           .caunt{
            small{
                color: $primaryDark;
            }
           }
           .mat-dialog-title{
            font: 500 20px/32px ;
            h4{
                font-weight: 700;
                font-size:$text-size-low;
                color: #080206;
                letter-spacing: 0;
                text-align: center;
                padding: 10px 0;
                span{
                    color: $familyClubBasic;
                }
            }
           }
           .upgrade-container{
            padding: 0;
            margin: 0;
            margin-bottom: 8px;
            li{
                list-style: none;
                font-size: $text-size-little;
                font-weight: initial;
                line-height: 20px;
                color: #333;
                letter-spacing: 0;
                padding: 0 0 12px;
                .check_circle{
                    font-size: 13px;
                    position: absolute;
                    padding: 2px 0 0;
                    margin: 0;
                    color:$familyClubBasic
                }
                span{
                    display: inline-block;
                    margin-left: 25px;
                    line-height: 17px;
                }
            }
           }
          
        }
        .group-member{
            padding: 0;
            text-align: center;
            h6{
                font-weight: Bold;
                font-size: $text-size;
                color: #080206;
                letter-spacing: 0;
                text-align: center;
                margin: 0;
                span{
                    color: $familyClubBasic;
                }
            }
            .price{
                font-size: 28px;
                font-weight: 700;
                line-height: initial;
                color: #080206;
                letter-spacing: 0;
                text-align: center;
                display: block;
            }
            .month{
                font-size: $text-size;
            }
            .year{
                font-size: $text-size-little;
                color: gray;
                letter-spacing: 0;
                text-align: center;  
            
            }
        }
        .choose-vclub{
            margin: 20px 0;
        }
        .mat-primary{
            background: $familyRegularClassic;
            color: #fff;
        }
        .btn-small{
            min-width: 100px;
            padding: 0 18px;
            line-height: 38px;
            font-size: 12px;
            border-radius: 20px; 
        } 
        .btn.mat-button-base{
            font-weight: 400;
            box-shadow: none;
            width: 100%;
            span{
                display: block;
                width: 100%;
            }
        }
        .mat-button-wrapper{
            color: $primary;
        }
        .btn.mat-button-base.mat-primary:not(:disabled):not(:focus):hover{
            background: $familyRegularPlus;
            box-shadow: 0 8px 20px 0 $shadowclubbtn;
        }
        .btn.mat-button-base.mat-quarternary:not(:disabled):not(:focus):hover {
            background: rgba(255,255,255,.05);
            box-shadow: 0 8px 20px 0 $shadowclubbtn;
        }
    }
}
/* .modal-body{
    padding: 0 ;
    border-radius: 12px 0 0 12px;
    -webkit-border-radius: 12px 0 0 12px;
    -moz-border-radius: 12px 0 0 12px;
    -ms-border-radius: 12px 0 0 12px;
    -o-border-radius: 12px 0 0 12px;
    overflow: hidden;
} */

@media (max-width: $lg) {
    .box-detail-flight{
        max-height: inherit;
    }
}

@media (max-width: $sm) {
   
}