@import "../../../../theme/variable-switch.scss";
.hotelroom {
  .container-card-room {
    box-shadow: $box-shadow-card;
  }
	.container-text-icon {
		font-weight: bold;
	}
}
@media (min-width: $md) {
  .hotelroom {
    .container-card-room {
      flex-direction: row !important;

      .container-card-img {
        .container-tag {
          display: none;
        }
      }

      .card-body {
        border-top: none !important;
      }
    }
  }
}

@media (max-width: $md) {
  .hotelroom {
    .container-card-room {
      flex-direction: row !important;

      .container-card-img {
        height: auto !important;
        .container-tag {
          display: none;
        }
      }

      .card-body {
        border-top: none !important;
      }
    }
  }
}

.selected-active {
  border: 2px solid $border;
  border-top-left-radius: 14px !important;
  border-bottom-left-radius: 10px !important;

  .container-tag-selected-room {
    display: block !important;
  }

  .container-tag {
    top: 40px;
  }
}

.container-card-room {
  height: 220px !important;
  border-radius: $border-radius;

  .container-card-img {
    display: flex;
    width: 270px;
    height: 100%;
    overflow: hidden;
    border-top-left-radius: $border-radius;
    border-bottom-left-radius: $border-radius;
    position: relative;

    .carousel {
      width: 100%;

      .carousel-inner {
        height: 100%;
      }
      .carousel-item {
        height: 100%;
      }

      .carousel-indicators {
        display: none;
      }

      .carousel-control-prev,
      .carousel-control-next {
        opacity: 0.7;
        &:hover {
          opacity: 0.8;
        }
      }
      .carousel-control-prev-icon,
      .carousel-control-next-icon {
        background-color: black;
        border-radius: 50%;
        background-size: 50% 50%;
        font-weight: 900;
        opacity: 0.7;
        &:hover {
          opacity: 0.9;
        }
      }
    }

    .card-img {
      border-top-right-radius: 0;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .container-tag-selected-room {
      display: none;
      padding: 10px;
      height: 40px;
      position: absolute;
      right: 0;
      width: 100%;
      text-align: center;
      background: $success;
      font-weight: 700;
      color: $textWhite;
      font-size: $font-card-tag;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    .container-tag {
      padding: 10px;
      border-top-right-radius: $border-radius;
      border-bottom-left-radius: $border-radius;
      height: 40px;
      position: absolute;
      right: 0;
      text-align: center;
      background: $primary;
      font-weight: 700;
      color: $textWhite;
      font-size: $font-card-tag;
      .container-icon {
        font-size: $font-card-icon;
        margin-right: 5px;
      }
    }

    .container-number-img {
      position: absolute;
      bottom: 10px;
      right: 10px;
      padding: 5px 15px;
      background-color: $background-number-imag;
      color: $textWhite;
      border-radius: $border-radius;
    }
  }

  .btn-showmore {
    margin: 10px 0;
    cursor: pointer;
    text-decoration: underline;
    font-size: $text-size-little;
  }

  .card-body {
    border-top: 5px solid $primary;

    .container-card-body-header {
      display: flex;
      flex-direction: row;
      width: 100%;
      margin-bottom: 10px;

      .title {
        font-size: $text-size-medium;
        font-weight: $font-weight-bold;
        color: $text-card-color;
        margin-bottom: 5px;
        height: fit-content;
      }
      .title:hover {
        color: $text-card-color-hover;
      }
    }

    .container-aux {
      display: flex;
      width: 100%;
      justify-content: space-between;
			align-items: flex-start;
			height: 100%;
			
    }
    .container-left {
      display: flex;
			align-self: stretch;
      flex-direction: column;
      padding-right: 15px;
			gap: 3px;
      .container-text-icon {
        justify-content: start;
      }

      .container-icon-amenities {
        display: flex;
        justify-content: start;
        height: fit-content;
        margin-bottom: 20px;

        .container-icon {
          display: inline-flex;
          color: $text-color;
          margin-right: 5px;
          &.hover {
            color: var(--primary);
          }
        }
				&:empty{
					display: none;
				}
      }

      .container-policies {
        display: flex;
        flex-direction: column;
        color: $text-color;
        font-size: 14px;
      }
    }

		.container-right{
			display: flex;
			align-self: center;
		}

    .rate-discount {
      color: $success;
      display: flex;
      align-items: center;
      font-size: $text-size;
    }
    .rate-offe {
      font-size: $text-size-little;
    }
  }

  &:hover {
    border-color: $success;
  }
}

.informations-tax {
  text-align: end;
  font-size: $text-size-small !important;
  font-weight: 700 !important;
  button.btn-reservar.btn.btn-primary {
    width: 200px;
    border-radius: 0px;
    @media (min-width: $sm) {
      width: 100px !important;
    }
  }
}

@media (max-width: $sm) {
  .box-tittle-column-state-about-hotel {
    .box-info-hide {
      -webkit-line-clamp: 2;
    }
  }

  .hotelroom {
    .container-card-room {
      flex-direction: column !important;
      height: initial !important;
      border-top-right-radius: 12px;

      .container-card-img {
        width: 100%;
        height: 230px;
        border-top-left-radius: $border-radius;
        border-top-right-radius: $border-radius;
        border-bottom-left-radius: 0;

        .card-img {
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
        }
      }

      .card-body {
        flex-direction: column;

        .title {
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          font-size: $font-card-tittle-xs !important;
        }

        .container-left {
          padding-right: 0;
          margin-bottom: 20px;

          .container-text-icon {
            margin-top: 5px;
          }
          .container-policies {
            font-size: $text-size-small;
          }
          .rate-discount {
            font-size: $text-size-little;
            display: none;
          }
          .rate-offe {
            font-size: $text-size-little;
          }
        }
      }
    }
  }
}

@media (min-width: $md) and (max-width: $lg) {
  .container-card-room {
    .card-body {
      .container-card-body-header {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
      }
    }
  }
}
