.btn-close {
	border: none !important;
	outline: none !important;
	border-color: transparent !important;
	box-shadow: none !important;
}

.accordion .accordion-header .accordion-button {
	padding-left: 0;
	padding-right: 0;
	background-color: transparent !important;

	&:focus,
	&:active {
		border: none !important;
		outline: none !important;
		border-color: transparent !important;
		box-shadow: none !important;
	}

	&:hover {
		color: var(--primary);
	}
}

.accordion-item {
	border: none;

	.form-check {
		display: flex !important;
		align-items: center;
		padding: 0;
		vertical-align: middle;

		&:hover *{
			cursor: pointer;
			color: var(--primary);
		}
	}

	.form-check-input {
		margin: 0 !important;
		vertical-align: middle;
		margin-right: 5px !important;
	}

	.card-content .box-star {
		line-height: 0;
	}

	.card-content .container-categories span {
		font-size: inherit;
	}
}

button.btn-filter.btn.btn-text {
	border: none;
}

button.clear-filters {
	text-decoration: none;
	display: flex;
	align-items: center;
	gap: 5px;
}

.offcanvas-body {
	padding-bottom: 60px;
}

.offcanvas-bottom {
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
	border-top: 1px solid var(--bs-border-color);
	background: var(--bs-offcanvas-bg);
}