@import "../../../../theme/variable-switch.scss";

@import "../../../form/inputs/Calendar/components/calendarStyle.scss";
@import "../../../form/inputs/SelectRooms/components/selectRoomsStyle.scss";

.booking-engine {
  background-color: var(--background-booking);

  border-color: $box-border-color;
  border-radius: $box-radius;
  box-shadow: $box-shadow-box;
  padding: 0rem 2rem 1rem 2rem;

  .title {
    padding-top: 1.2rem;
  }

  h1,
  h2,
  .h2 {
    font-weight: 500;
    color: $box-color;
    margin-bottom: 20px;
    font-size: $text-size-big;
  }

  .booking-engine-row {
    display: flex;
    gap: 1rem;
  }

  .booking-engine-col {
    justify-content: center;
    padding: 0;
  }

  button {
    border-radius: $border-radius-btn;
    padding: 0.5rem 1rem;
    display: flex;
    height: 48px;
    flex-direction: row;
    align-items: center;
    padding-top: 0;
    padding-bottom: 0 !important;

    svg {
      margin-right: 5px;
    }
  }

  .search-room-button-render-input {
    min-width: 120px;
  }

  .calendar-button-render-input {
    min-width: 220px;
  }

  .btn-book-submit {
    min-width: 100%;
    justify-content: center;
    &:active {
      background: $primary;
      border-color: $primary;
    }
  }

  .alert-text {
    position: relative;
    height: 10px;
    color: $error;
    font-size: $text-size-little;
    padding-top: 3px;
  }
}

button.btn-close.btn-close-white {
  filter: $breadcrumb-close-btn;
}

.rdrMonthName{
	text-transform: capitalize;
}

.calendar-modal {
	.rdrMonthName{
		padding: 5px 0;
	}
}
// .btn-check:checked+.btn,
// :not(.btn-check)+.btn:active,
// .btn:first-child:active,
// .btn.active,
// .btn.show {
//     background: $primary;
//     border-color: $primary;

//     &.calendar-button-render-input:active,
//     &.search-room-button-render-input:active {
//         background: white;
//     }
// }

@media (max-width: $sm) {
  .booking-engine {
    // display: flex;
    .calendar-button-render-input {
      min-width: 100%;
    }

    h1,
    h2,
    .h2 {
      font-size: $text-size-medium;
    }

    .btn-book-submit {
      justify-content: center;
      width: 100%;
    }
  }

  .box-search-select-options-rooms {
    max-height: auto;
    overflow-y: inherit;
  }

  .calendar-modal {
    .rdrCalendarWrapper {
      width: 100%;
    }

    .rdrMonths {
      flex-flow: column;
    }

    .rdrMonth {
      width: 100%;
    }

    .popover-calendar-button {
      border: none;
    }

    .popover-arrow {
      display: none;
    }
  }

  .calendar-modal-close {
    position: fixed;
    width: 100%;
    left: 0;
    text-align: center;
    padding: 15px;
    padding-bottom: 30px;
    background: $backgroundBox;
    overflow: hidden;
    bottom: 0;

    button {
      width: 100%;
    }
  }

  .calendar-preview {
    text-align: center;
    font-size: 20px;
  }

	.modal-fullscreen .calendar-modal{
		.rdrMonth{
			padding-bottom: 0px;
		}

		.rdrMonth + .rdrMonth{
			margin-top: 10px;
		}

		

		.rdrMonthAndYearWrapper{
			height: 60px !important;
		}
	}
}

// @media only screen and (min-width:  $sm) and (max-width:$md) {
@media (max-width: $md) {
  .booking-engine-row {
    display: flex;
    flex-direction: column;
  }
}
