@import "../../../../theme/variable-switch.scss";
@import "../../BoxIconText/components/boxIconText.scss";

.service-detail-main-tour {
  .main-subtitle {
    font-size: $text-size-aux;
  }
  .service-detail-container-text-icon-span {
    .container-icon {
      font-size: $text-size;
    }
    span {
      font-size: $text-size;
    }
  }
}

.box-detail-service-title-rate {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
@media (max-width: $sm) {
  .service-detail-main-tour {
    .main-subtitle {
      font-size: $text-size-low;
    }
    .service-detail-container-text-icon-span {
      .container-icon {
        font-size: $text-size;
      }
      span {
        font-size: $text-size-little;
      }
    }
  }

  .box-detail-service-title-rate {
    flex-direction: column;
    justify-content: start;
  }
}
