@import "../../../theme/variable-switch.scss";

.cards-iconBasic-section {
  .box-text-banner-container {
    display: grid;
    justify-content: center;
    color: $color-background-b2b;
    margin-bottom: 1rem;
    .title {
      text-align: center;
      font-size: $text-size-big;
      font-weight: 700;
      margin-bottom: 1rem;
    }
    .subtitle {
      line-height: 15px;
      text-align: center;
      font-size: $text-size-big !important;
      margin-bottom: 1rem;
    }
  }
  .card-iconBasic {
    border: none;
    align-self: center;
    .title {
      font-weight: 700;
      color: $color-background-b2b;
    }
    .image-iconBasic {
      margin-bottom: 1rem;
      max-width: 102px;
      align-self: center;
      max-height: 102px;
    }
    .title {
      text-align: center;
    }
  }

  @media (max-width: $md) {
    .box-text-banner-container {
      margin-bottom: 0.3rem;
      .subtitle {
        line-height: 15px;
        font-size: $text-size-aux !important;
      }
    }
    .card-iconBasic {
      .image-iconBasic {
        font-weight: 400;
        max-width: 70px !important;
        max-height: 70px !important;
        margin-bottom: 0.6rem !important;
      }

      .title {
        font-size: $text-size;
      }
    }
  }
}

.vacation-life-section {
  padding-top: 2rem;
  padding-bottom: 2rem;
  .sliders-container .container-title {
    color: $color-background-b2b;
    font-weight: 4800;
    font-size: $text-size-aux !important;
  }
  .sliders-container .slider-row{
    position: relative;
    z-index: -1;
  }
}
