.autocomplete-list {
	input {
		width: 100%;
		padding-bottom: 15px;
		border-color: transparent;
		border-bottom: 1px solid var(--bs-gray-300);
		border-radius: 0;
		outline: none;

		&:focus {
			border-color: transparent;
			border-bottom: 1px solid var(--bs-gray-500);
			box-shadow: none;
		}
	}

	.no-suggestions {
		padding-top: 30px;
		text-align: center;
	}

	.suggestions {
		padding-top: 15px;
		padding-left: 0;

		li {
			display: block;
			cursor: pointer;
			padding: 8px 15px;
			display: flex;
			align-items: center;
			justify-content: flex-start;

			&:hover {
				background-color: var(--bs-gray-300);
			}

			svg {
				padding-right: 10px;
				font-size: 25px;
			}

			span {
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}
		}
	}


}