@use "../variable-switch.scss" as theme;

@use '../../../node_modules/@mzp/commons/dist/src/scss/main.scss' with (
    $background-secondary:rgb(26, 26, 26),
    $primary: theme.$primary,
    $primaryLight: theme.$primaryLight,
    $primaryDark: theme.$primaryDark,
    $secondary: theme.$secondary,
    $secondaryLight: theme.$secondaryLight,
    $secondaryDark: theme.$secondaryDark,
    $headerBackgound: theme.$headerBackgound,
    $textMenu:theme.$textMenu,
    $textHeaderMenuHover: theme.$textHeaderMenuHover,
    $dropDownMenuBackground: theme.$dropDownMenuBackground,
    $textDropDown: theme.$textDropDown,
    $activeItemDropdown: theme.$activeItemDropdown,
    $headerMobileBackground: theme.$headerMobileBackground,
    $textMenuMobile : theme.$textMenuMobile,
    $iconMenuBurger: theme.$iconMenuBurger,
    $chipBackground: theme.$chipBackground,
    $chipBackgroundColor: theme.$chipBackgroundColor,
    $chipBackgroundActive: theme.$chipBackgroundActive,
    $chipBackgroundActiveColor: theme.$chipBackgroundActiveColor,
    $galleryBackground: theme.$galleryBackground,
    $galleryfontColor: theme.$galleryfontColor,
    $galleryIconActive: theme.$galleryIconActive,
    $galleryFontSize: theme.$galleryFontSize,
);

@use '../../../node_modules/@mzp/components/dist/src/scss/main.scss' as themeComponents with (
    $background-secondary:rgb(26, 26, 26),
    $primary: theme.$primary,
    $primaryLight: theme.$primaryLight,
    $primaryDark: theme.$primaryDark,
    $secondary: theme.$secondary,
    $secondaryLight: theme.$secondaryLight,
    $secondaryDark: theme.$secondaryDark,
    $background-boxImageBooking-inputMobile:theme.$background-boxImageBooking-inputMobile,
    $borderColorHover-boxImageBooking-inputMobile: theme.$borderColorHover-boxImageBooking-inputMobile,
    $borderColor-boxImageBooking-inputMobile: theme.$borderColor-boxImageBooking-inputMobile,
    $search-label-error: theme.$search-label-error,
    $search-background-label-error: theme.$search-background-label-error
);

.banner-home-section {
  padding-top: 2rem;
  padding-bottom: 2rem;
}
.vacation-life-section {
  padding-top: 2rem;
  padding-bottom: 2rem;
}
.banner-flat-section { 
  padding-top: 2rem;
  padding-bottom: 2rem;
  .container-banner-simple-flat {
    margin: auto;
    border-radius: theme.$border-radius;
  }
  @media (max-width: theme.$sm) {
    display: none;
  }
}
.banner-video-right-section {
  padding-top: 2rem;
  padding-bottom: 2rem;
}
.top-destination-section {
  padding-top: 2rem;
  padding-bottom: 2rem;
}
.top-things-to-do-section {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.header .style-nav-center .logo_header {
  object-fit: contain;
}
.p-autocomplete-item {
  svg {
    color: theme.$primary;
  }
}

.wrapper-collage {
  .p-chip-active {
    .p-chip-text {
      color: theme.$textWhite !important;
    }
  }
}

.gallery-grid-container .gallery-footer .gallery-footer-wrapper-landscape {
  .active {
    font-weight: 900;
  }
  .inactive {
    font-weight: 500;
  }
}

// font buscador
.mzp-autocomplete.has-icon input {
  font-weight: theme.$font-weight-bold;
  color: theme.$text-color;
}
.search-box button {
  font-weight: theme.$font-weight-bold;

  color: theme.$text-color;
}

.search-box-service-hotel {
  .text-end {
    margin-top: -1.5rem;
  }
}

.text-end-fixed {
  margin-top: -0.3rem !important;
}
.mzp-autocomplete span.label-icon{
 z-index: 4;
}
@media (max-width: theme.$sm) {
  .search-box-service-hotel {
    .text-end {
      margin-top: 0rem;
    }
  }
}

