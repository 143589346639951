@import "../../../../theme/variable-switch.scss";

.container-number-img {
  position: absolute;
  bottom: 10px;
  right: 10px;
  padding: 5px 15px;
  background-color: $background-number-imag;
  color:$textWhite;
  border-radius: $border-radius;
  cursor: pointer;
  svg {
    color:$textWhite;
  }
}