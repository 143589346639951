@import "../../../../theme/variable-switch.scss";
@import "../../BoxIconText/components/boxIconText.scss";

.box-list-flight {
  overflow: hidden;
  position: relative;
  background-color: white;
  border-radius: 10px;
  border-color: #565656;
  box-shadow: 0px 0px 1px 0px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%),
    0px 1px 8px 0px rgb(0 0 0 / 12%);

  &:hover {
    box-shadow: 0 0.13rem 0.5rem 0 rgb(0 0 0 / 6%),
      0 1.25rem 1.56rem 0 rgb(0 0 0 / 8%);
    transition: box-shadow ease-in-out 0.3s;
  }

  .info-flight {
    display: flex;
    justify-content: space-between;
    margin: 10px 0;
    align-items: baseline;

    div {
      font-size: $text-size-mini;
    }

    .flight-btn-detail {
      font-size: $text-size-small;
      color: $primary;
      cursor: pointer;
      font-weight: 600;

      &:hover {
        color: $text-colorLight;
      }
    }
  }

  .btn-open-panel {
    border: none;
    /*  border-bottom: 1px solid $bacgroundScaleLight; */
    display: flex;
    width: 100%;
    height: 100%;
    text-align: center;
    padding: 0;
    background-color: white;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
  }

  .panel-open {
    border-left: 1px solid rgba(0, 0, 0, 0.1);
  }

  .price-tab {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;

    .seat-left {
      font-size: $text-size-mini;
      position: absolute;
      top: 10px;
    }
  }

  .priceDiscounted {
    font-size: $text-size-small;
    line-height: 15px;
    min-height: 15px;
    color: $tag;
    margin-top: 10px;
    text-decoration: line-through;
  }

  .price {
    font-size: $text-size-tall;
    line-height: 27px;
    color: $background-secondary;
    font-weight: 700;
    margin-top: 0;
    margin-bottom: 0;
    white-space: nowrap;

    small {
      font-size: $text-size-little;
    }
  }

  .type-regular {
    .panel-close {
      border-left: 1px solid $borderRegular;
      border-right: 1px solid $borderRegular;
      border-bottom: none;
    }

    .borderRegular {
      border-bottom: 1px solid $borderClub;
    }
  }

  .type-vclub {
    .panel-close {
      border-left: 1px solid $borderClub;
      border-right: 1px solid $borderClub;
      border-bottom: none;
    }

    .borderClub {
      border-bottom: 1px solid $borderRegular;
    }

    .tooltip-club {
      font-size: $text-size-low;
    }
  }

  .priceRegular {
    &:hover {
      background: $backgroundRegular;
      border-bottom: 1px solid white;
    }
  }

  .priceVclup {
    &:hover {
      background: $backgroundClub;
      border-bottom: 1px solid white;
    }
  }

  .accordion-item:first-of-type .accordion-collapse {
    margin-top: -1px;
    border: 1px solid $borderRegular;
    /* border: 1px solid red; */
    border-radius: 0 0 12px 12px;
  }

  .accordion-item:last-of-type .accordion-collapse {
    margin-top: -1px;
    border: 1px solid $borderClub;
    /* border: 1px solid blue; */
    border-radius: 0 0 12px 12px;
  }

  .headerTab {
    width: 100%;
    color: white;
    border-radius: 4px 4px 0px 0px;
    padding: 4px;
    min-height: 30px;
    position: absolute;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    h6 {
      font-size: $text-size-small;
      margin-bottom: 0;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 5px;
    }
  }

  .priceRegular {
    .headerTab {
      background-color: $bacground-flight;
    }
  }

  .priceVclup {
    .headerTab {
      background-color: $bacground-flight-discount;
    }
  }

  .card-ribbon {
    position: absolute;
    overflow: hidden;
    top: -10px;
    left: -19px;
    width: 110px;
    height: 102px;

    span {
      position: absolute;
      width: 90px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 3px 0;
      background-color: $tag;
      box-shadow: 0 5px 5px rgb(0 0 0 / 20%);
      color: #fff;
      font-size: $text-size-mini;
      /* text-transform: uppercase; */
      text-align: center;
      left: -11px;
      top: 16px;
      transform: rotate(-45deg);
    }

    &::before,
    &::after {
      position: absolute;
      z-index: -1;
      content: "";
      display: block;
      border: 5px solid #2980b9;
      border-top-color: transparent;
      border-left-color: transparent;
    }

    &::before {
      top: 0;
      right: 0;
    }

    &::after {
      bottom: 0;
      left: 0;
    }
  }
}

@media (max-width: $md) {
  .box-list-flight .price-tab {
    margin-top: 50px;
    margin-bottom: 20px;
  }

  .box-list-flight .price-tab .seat-left {
    top: 37px;
  }
}
