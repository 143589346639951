@import "../../../../theme/variable-switch.scss";

.collage {
	display: flex;
	flex-direction: row;
	width: 100%;
	height: 58vh;

	.container-first {
		display: flex;
		width: 40%;
	}

	.container-duo {
		width: 30%;
		padding-left: 3.5px;

		.container-top {
			display: flex;
			width: 100%;
			height: 50%;
			padding-bottom: 3.5px;
		}

		.container-botom {
			display: flex;
			width: 100%;
			height: 50%;
		}
	}

	img {
		object-fit: cover;
	}
}

@media (max-width: $sm) {
	.collage {
		width: 100%;
		flex-direction: column;
		height: auto;

		.container-first,
		.container-duo {
			width: 100%;
		}
	}
}