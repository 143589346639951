@import "../../../../theme/variable-switch.scss";

.footer {
  background-color: $footer-background !important;
  color: $font-footer-color !important;
  display: flex;
  width: 100% !important;
  flex-direction: column;
  font-size: $font-footer-text;
  p,
  a {
    font-size: $font-footer-text;
  }

  .footer-title {
    font-size: $font-footer-title;
  }

  .navbar-brand {
    h3 {
      font-size: $font-footer-logo;
    }
  }

  .footer-container-colums {
    display: flex;
    color: $font-footer-color !important;
    padding-bottom: 60px;
    padding-top: 60px;
    flex-wrap: wrap;
    justify-content: space-around;

    .footer-logo {
      width: max-content !important;
      margin-bottom: 10px;
    }

    .text-mini-left {
      font-size: $font-footer-text;
    }

    .footer-column {
      padding-left: 30px;
      display: flex;
      flex-direction: column;
    }

    .footer-title {
      font-size: $font-footer-title;
    }

    .footer-link {
      color: $font-footer-color !important;
      width: fit-content;

      &:hover {
        color: $font-footer-color-hover ;
        text-decoration: underline !important;
      }
    }

    .footer-whitout-link {
      color: $font-footer-color;
      padding: 8px 16px;
    }

    .footer-subtitle-resalt {
      font-size: $font-footer-information;
      font-weight: 700;
      color: $font-footer-color-resalt !important;
      margin-bottom: 10px;
    }
  }
}

@media (max-width: $sm) {
  .footer {
    .footer-container-colums {
      flex-direction: column;
      text-align: center;

      .text-mini-left {
        font-size: $text-size;
      }

      .footer-title {
        font-size: $text-size-medium;
      }

      .footer-column {
        padding-left: 15px;
        padding-right: 15px;
        margin-bottom: 15px;

        .footer-link,
        .footer-whitout-link {
          font-size: $text-size;
          text-align: center;
          margin-left: auto;
          margin-right: auto;
        }
      }
    }
  }
}
