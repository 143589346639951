@import "../../../../theme/variable-switch.scss";
.position-map {
	position: relative;
	width: 100%;
	height: 100%;
}

.container-map-cards-bloque {
	position: absolute;
	z-index: 1;
	transform: translate(calc(-50% - -50px), 5px);
	.card-container-map {
		display: flex;
		justify-content: center;
		overflow: hidden;
		border-radius: $border-radius;

		.carousel {
			overflow: initial;
		}

		.card-body {
			width: auto;
			overflow: auto;

			.container-text-icon .container-icon {
				font-size: 12px;
			}

			.container-flex {
				gap: 5px;
			}
		}
	}

	&.isMobile {
		position: fixed;
		width: 100%;
		max-width: 500px;
		padding: 0 10px;
		transform: translate(-50%, 0);
		height: 175px;

		.card-container-map {
			width: 100%;
			height: 100%;

			.container-card-general {
				display: flex;
				flex-direction: row;
				width: 100%;

				.card-container-image {
					width: 200px;
					flex: 1 0 200px;
				}

				.container-img {
					overflow: hidden;
				}

				.card-img {
					border-radius: $border-radius;
					object-fit: cover;
					object-position: center;
					aspect-ratio: 1;
					height: auto !important;

					&.loading .spinner-border {
						height: 2rem;
						width: 2rem;
					}
				}

				.card-body {
					flex: 2 2 auto;

					.close-button {
						font-size: 11px;
						width: 22px;
						height: 22px;
					}

					.container-icon {
						display: none;
					}

					.container-text {
						font-size: 11px;
					}

					.box-title-content-title {
						font-size: 16px;
						padding-right: 20px;
					}

					.box-price {
						.rate-without-discount {
							font-size: 12px;
						}

						.box-item-price {
							font-size: 16px;
						}

						.box-item-text {
							font-size: 10px;
						}
					}
				}
			}
		}
	}
}

.container-circles-fab-sun {
	display: block;
	width: 50px;
	height: 50px;
	border-radius: 50%;
	padding: 2px;
	border: 3px dashed;
	border-color: $primary;

	.fab-sun {
		box-shadow: none;
		background: white;
		border: 2px solid;
		border-color: $primary;
		border-radius: 50%;
		width: 100%;
		height: 100%;
		text-align: center;
		display: flex;
		align-items: center;
		justify-content: center;
	}
}

.container-map-prices {
	left: -50px;
	top: -40px;
	position: absolute;

	.chip-arrow {
		padding: 5px 10px;
		background: white;
		border-radius: 25px;
		font-size: $text-size-little;
		color: $primary;
		border: 1px solid $primary;
		cursor: pointer;
		white-space: nowrap;

		&.chip-arrow-selected {
			background: $primaryDark;
			color: $textWhite;
			padding: 7px 15px 7px
		}

		&.chip-arrow-selected::before {
			background: $primaryDark;
			border-color: $primaryDark;
			color: $textWhite;
		}
	}

	.chip-arrow:before {
		content: "";
		display: block;
		position: absolute;
		top: 29px;
		left: 42%;
		width: 10px;
		height: 10px;
		background-color: #fff;
		border: 1px solid $primary;
		border-top-color: white;
		border-left-color: white;
		-webkit-transform: translateY(-50%) rotate(45deg);
		-moz-transform: translateY(-50%) rotate(45deg);
		-ms-transform: translateY(-50%) rotate(45deg);
		transform: translateY(-50%) rotate(45deg);
		z-index: 0;
	}
}

.container-map-prices:hover {
	.chip-arrow {
		padding: 7px 15px 7px;
		border-radius: 25px;
		font-size: $text-size-little;
		border: 1px solid $primary;
		cursor: pointer;
		background: $primaryDark;
		color: $textWhite;
	}

	.chip-arrow:before {
		background: $primaryDark;
		color: $textWhite;
		border: 1px solid $primaryDark;
		top: 30px;
	}
}

.container-text-icon {
	display: flex;
	justify-content: center;

	.container-icon {
		display: inline-flex;
		padding-right: 5px;
	}

	.container-text {
		display: inline-flex;
		font-size: $text-size-small;
		padding-left: 0;
	}

	.color-primary {
		color: $primary;
	}
}

@media (max-width: 914px) AND (min-width: 769px) AND (orientation: landscape) {
	.container-map-cards-bloque {
		max-width: 450px;
	}
}

@media (max-width: 480px) {
	.container-map-cards-bloque {
		.card-container-image .card-content-img{
			min-width: 120px;
		}
		.card-container-map {
			.carousel,
			.card-img {
				width: 100%;
				min-height: 175px;
			}
		}
	}
}

@media (max-height: 420px) AND (orientation: landscape) {
	.container-map-cards-bloque.isMobile {
		max-width: 400px;
		height: 145px;
		transform: translate(-50%, 10px);
		.card-container-map {
			.container-card-general {
				.card-container-image{
					width: 130px;
					flex: 1 0 130px;
				}
				.card-img {
					aspect-ratio: 9/11;
				}
				.card-body .container-text{
					font-size: 10px;
				}
			}
		}

		.container-text-icon:not(:first-child){
			display: none !important;
		}
	}
}

@media (max-width: 380px) {
	.container-map-cards-bloque.isMobile {
		.card-container-map {
			.container-card-general {
				.card-container-image {
					width: 120px !important;
					flex-basis: 120px !important;
				}

				.card-label {
					font-size: 10px;
				}
			}
		}
	}
}

@media (max-width: 340px) {
	.container-map-cards-bloque.isMobile {
		.card-container-map {
			.card-body {
				padding: 0.5rem;
			}
		}
	}
}