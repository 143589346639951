@import url("../../../../theme/variable-switch.scss");

.wrapper-collage{
  padding-left: 0px !important;
.modal-header {
    padding: 5px;
}    
.modal-body {
    height: 100% !important;
    padding: 0px;
}
.box-tittle-column-state {
    height: 300px;
    overflow: hidden;
    transition-duration: 3s, 2s;
    transition-delay: 2s, 3s;
}

.box-tittle-column-state-about-hotel {
    height: 125px;

    transition-duration: 3s, 2s;
    transition-delay: 2s, 3s;
}

}