@import "../../../../theme/variable-switch.scss";

//flight
.box-timeline{
    display: flex;
    justify-content: space-between;
    align-items: center;
    .timeline-scale{
        position: absolute;
        width: 100%;
        text-align: center;
        font-size:$text-size-mini ;
    }
    .scale-top{
        top: -20px;
    }
    .scale-bottom{
        top: 10px;
    }
}
.timeline-date{
    p{
        font-size: $text-size-low;
        font-weight: 700;
        margin-bottom: 0;
    }
    span{
        font-size: $text-size-small;
    }
    width: 35%;
}
.linepoint{
    position: relative; 
    min-height: 7px;
    width: 100%;
    .line{
        position: absolute;
        height: 1px;
        margin-top: 3px;
        margin-bottom: 3px;
        width: 100%;
        background-color: $background-secondary;
    }
    .circle{
        height: 7px;
        width: 7px;
        background: $background-secondary;
        border-radius:50%;
        position: absolute;
    
    }
    .circle-scale{
        height: 7px;
        width: 7px;
        background:$textWhite;
        border-radius:50%;
        border-radius: 3px;
        border: 1px solid $background-secondary;
        position: absolute;

}
}

@media (max-width: $sm) {
    .linepoint{
        width: 30%;
    }
}
//fin flight