@import "../../../../theme/variable-switch.scss";

.container-info-room {
	.container-text-icon {
		justify-content: flex-start !important;
	}

	.carousel-inner {
		height: 100%;
	}

	.contant-btn {
		width: 100%;
		background-color: $backgroundBox;
		padding: 20px;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.box-service-rate {
		align-items: baseline !important;
	}

	.info-room-slide-img {
		height: 400px;
	}

	.title {
		font-size: $text-size-aux;
		margin-bottom: 24px;
	}

	.subtitle {
		font-size: $text-size-tall;
	}

	button,
	.btn-reservation {
		margin-top: 10px;
		transition: 0.2s;
		font-size: $text-size;
		color: white;

		&:hover {
			color: white;
		}
	}
}

@media (max-width: $md) {
	.container-info-room {
		margin-bottom: 130px;
		.contant-btn {
			position: fixed;
			width: 100%;
			bottom: 0;
			margin-left: 0;
			left: 0;
			border-top: 1px solid $primaryLight;
		}

		.info-room-slide-img {
			height: 250px;
		}

		.title {
			font-size: $text-size-low;
		}

		.subtitle {
			font-size: $text-size-medium;
		}

		button,
		.btn-reservation {
			font-size: $text-size-little;
		}
	}
}