@import "../../../theme/variable-switch.scss";

.page-general {
  margin-top: 5rem;
  text-align: center;
  color: $primary;
  p {
    color: $text-color !important;
    margin: 2rem 4rem 2rem 4rem;
    text-align: justify;
  }
}
